import Link from 'next/link';
import classNames from 'classnames';
import Accordion from 'components/Tailwind/Module/Accordion';
import Card from 'components/Tailwind/Module/Card';
import Carousel from 'components/Tailwind/Module/Carousel';
import CompareSection from 'components/Compare/CompareSection';
import HorizontalScroll from 'components/Tailwind/Module/HorizontalScroll';
import HTML from 'components/Layout/Module/HTML';
import Image from 'components/Layout/Module/Image';
import NewsArticle from 'components/Layout/Module/NewsArticle';
import PageNavigation from 'components/Tailwind/Module/PageNavigation';
import ProductsBody from 'components/Tailwind/Module/ProductsBody';
import ProductHeader from 'components/Tailwind/Module/ProductsBody/ProductHeader';
import Publication from 'components/Layout/Module/Publication';
import Selector from 'components/Tailwind/Module/Selector';
import Study from 'components/Layout/Module/Study';
import Testimonial from 'components/Tailwind/Module/Testimonial';
import Guide from 'components/Tailwind/Module/Guide';
import Text from 'components/Tailwind/Module/Text';
import Video from 'components/Layout/Module/Video';
import PageSet from 'components/Layout/Module/PageSet';
import { Breadcrumb, ConditionalWrapper } from '@viome/vnxt-nucleus';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import GridDropdown from 'components/Tailwind/Module/GridDropdown';
import ProductSelector from 'components/Tailwind/Module/ProductSelector';
import ProductSelectorV2 from 'components/Tailwind/Module/ProductSelectorV2';
import Row from 'components/Tailwind/Row';
import Person from '../Person';
import Grants from '../Misc/Grants';
import Careers from '../Misc/Careers';

export default function Modules({
    modules,
    styles = {},
    columns,
    darkMode,
    attrs = {},
}) {
    const modulesArray = [],
        length = (modules && modules.length) || 0,
        max = length;

    let layoutOptions = {
            styles: {},
        },
        moduleLayoutOptions = {};

    if (length > 0) {
        for (let i = 0; i <= max - 1 && i < length; i++) {
            const moduleFields = modules[i],
                id = moduleFields?.sys.id,
                moduleType = moduleFields.__typename;

            let moduleContent;

            if (moduleType === 'moduleImage') {
                moduleContent = (
                    <Image
                        data={moduleFields.src}
                        mp4={moduleFields.mp4}
                        options={{
                            align: moduleFields.align,
                            alt: moduleFields.alt,
                            caption: moduleFields.caption,
                            width: moduleFields.displayWidth,
                            widthMobile: moduleFields.maxWidthMobile,
                            widthTablet: moduleFields.maxWidthTablet,
                            layout: moduleFields.layout,
                            priority: moduleFields.priority,
                            quality: moduleFields.quality,
                            mobile: moduleFields.srcMobile,
                            tablet: moduleFields.srcTablet,
                            layoutOptions: moduleFields.layoutOptions,
                        }}
                    />
                );
                layoutOptions = layoutClasses(
                    moduleFields?.layoutOptions || {},
                    null,
                    styles,
                );
            } else if (moduleType === 'moduleText') {
                layoutOptions = layoutClasses(
                    moduleFields?.layout || {},
                    null,
                    styles,
                );

                moduleContent = <Text data={moduleFields} />;
            } else if (moduleType === 'video') {
                moduleContent = <Video data={moduleFields} />;
            } else if (moduleType === 'moduleAccordion') {
                moduleContent = (
                    <Accordion data={moduleFields} darkMode={darkMode} />
                );
                layoutOptions = layoutClasses(
                    moduleFields?.layout || {},
                    null,
                    styles,
                );
            } else if (moduleType === 'moduleCarousel') {
                moduleContent = <Carousel data={moduleFields} />;
            } else if (moduleType === 'moduleHorizontalScroll') {
                moduleContent = (
                    <HorizontalScroll data={moduleFields} styles={styles} />
                );
            } else if (moduleType === 'moduleCard') {
                moduleContent = <Card data={moduleFields} />;
            } else if (moduleType === 'newsItem') {
                moduleContent = <NewsArticle data={moduleFields} />;
            } else if (moduleType === 'productsNavMenu') {
                moduleContent = <PageNavigation data={moduleFields} />;
            } else if (moduleType === 'publication') {
                moduleContent = <Publication data={moduleFields} />;
            } else if (moduleType === 'study') {
                moduleContent = <Study data={moduleFields} />;
            } else if (moduleType === 'author') {
                moduleContent = (
                    <Person data={moduleFields} columns={columns || 1} />
                );
            } else if (moduleType === 'moduleHtml') {
                moduleContent = <HTML data={moduleFields} />;
            } else if (moduleType === 'moduleMisc') {
                const miscModuleType = moduleFields.moduleType,
                    miscModules = moduleFields.modules || [];

                if (miscModuleType === 'VLS - Grants Form') {
                    moduleContent = <Grants />;
                } else if (miscModuleType === 'Careers List') {
                    moduleContent = <Careers />;
                } else if (miscModuleType === 'Guide') {
                    moduleContent = miscModules.map((module) => (
                        <Guide fields={module.fields} darkMode={darkMode} />
                    ));
                } else if (miscModuleType === 'FAQs') {
                    const faqsItems = [];
                    for (let j = 0; j <= miscModules.length - 1; j++) {
                        faqsItems.push(
                            <Card
                                data={miscModules[j].fields}
                                key={miscModules[j].fields.name}
                                type="faqsItem"
                            />,
                        );
                    }
                    moduleContent = faqsItems;
                } else if (miscModuleType.includes('Product Grid')) {
                    const isBasic = miscModuleType
                        .split(' - ')[1]
                        .includes('Shop');

                    moduleContent = (
                        <ProductsBody
                            modules={miscModules}
                            settings={moduleFields?.settings || {}}
                            basic={!isBasic}
                        />
                    );
                } else if (miscModuleType.includes('Compare')) {
                    moduleContent = <CompareSection products={miscModules} />;
                } else if (miscModuleType.includes('Breadcrumb')) {
                    const items = [];
                    moduleFields?.modules.forEach((item) => {
                        const itemUrl = item.fields.url,
                            itemText = item.fields.linkText;

                        items.push({
                            title: itemText,
                            url: itemUrl,
                            wrapper: (
                                <ConditionalWrapper
                                    condition={itemUrl}
                                    wrapper={(nodeChildData) => (
                                        <Link
                                            href={itemUrl}
                                            itemProp="item"
                                            itemScope
                                            itemType="https://schema.org/ListItem"
                                            itemID={itemUrl}
                                        >
                                            {nodeChildData}
                                        </Link>
                                    )}
                                >
                                    {itemText}
                                </ConditionalWrapper>
                            ),
                        });
                    });
                    moduleContent = (
                        <Breadcrumb
                            items={items}
                            settings={moduleFields?.settings || {}}
                        />
                    );
                }
            } else if (moduleType === 'moduleTestimonial') {
                moduleContent = <Testimonial data={moduleFields} />;
            } else if (moduleType === 'moduleProductSelector') {
                moduleContent = <ProductSelectorV2 data={moduleFields} />;
            } else if (moduleType === 'moduleSelector') {
                const miscModuleType = moduleFields.interactionType;

                if (miscModuleType === 'product-selector') {
                    moduleContent = (
                        <ProductSelector
                            data={moduleFields}
                            settings={moduleFields?.settings || {}}
                        />
                    );
                } else if (miscModuleType === 'grid-dropdown') {
                    moduleContent = (
                        <GridDropdown
                            data={moduleFields}
                            settings={moduleFields?.settings || {}}
                        />
                    );
                } else {
                    moduleContent = (
                        <Selector data={moduleFields} darkMode={darkMode} />
                    );
                }
            } else if (moduleType === 'modulePageSet') {
                moduleContent = <PageSet data={moduleFields} />;
            } else if (moduleType === 'product') {
                moduleContent = (
                    <ProductHeader
                        productFields={moduleFields}
                        styles={styles}
                    />
                );
            } else {
                moduleContent = <Row data={moduleFields} nested />;

                layoutOptions = layoutClasses(
                    moduleFields?.layout || {},
                    null,
                    styles,
                );
            }

            moduleLayoutOptions = layoutClasses(
                moduleFields?.layoutOptions?.module || {},
                null,
                styles,
            );

            modulesArray.push(
                <div
                    className={classNames(
                        styles.modules,
                        moduleType && styles[moduleType],
                        moduleType && moduleType,
                        layoutOptions.rowClasses,
                        moduleLayoutOptions.classes,
                    )}
                    key={`${i}${id}`}
                    {...attrs}
                >
                    {moduleContent}
                </div>,
            );
        }

        return modulesArray;
    }
    return null;
}
