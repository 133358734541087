import Link from 'next/link';
import Popup from 'components/Tailwind/Components/PopupGeneric';
import TailwindRow from 'components/Tailwind/Row';
import CompareSection from 'components/Compare/CompareSection';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import { parseModules } from '@viome/vnxt-nucleus';
import compareStyles from 'pages/compare/Compare.module.scss';
import Text from 'components/Tailwind/Module/Text';

const Hyperlink = (props, popup) => {
    const { data, content } = props,
        nodeData = data,
        { value } = content[0],
        url = nodeData.uri,
        isInternal =
            url.includes('habit.com') ||
            url.charAt(0) === '#' ||
            url.charAt(0) === '/',
        isAnchor = url.charAt(0) === '#',
        parsedPopup = popup && parseModules([popup])[0],
        productFields = popup?.fields || {},
        {
            sku,
            healthScoresBody,
            shopifySubscriptionPlanProd,
            shopifySubscriptionPlanStaging,
        } = productFields,
        isPlan = shopifySubscriptionPlanProd || shopifySubscriptionPlanStaging;

    return (
        <>
            {popup ? (
                <>
                    {sku ? (
                        <StyleContextProvider styles={compareStyles}>
                            <Popup active={false} title={value}>
                                <CompareSection
                                    key={sku}
                                    products={[popup]}
                                    selectedItems={[sku]}
                                    sectionOrder={
                                        !isPlan
                                            ? ['title', 'optimizes', 'children']
                                            : sku === 'FCS'
                                            ? [
                                                  'title',
                                                  'productDetails.supplements',
                                                  'productDetails.prepro',
                                                  'test',
                                                  'optimizes',
                                                  'food',
                                                  'productDetails.details',
                                              ]
                                            : [
                                                  'title',
                                                  'productDetails.prepro',
                                                  'test',
                                                  'optimizes',
                                                  'food',
                                                  'productDetails.details',
                                              ]
                                    }
                                    popup
                                >
                                    {!isPlan && (
                                        <Text
                                            data={{
                                                body: healthScoresBody,
                                                style: 'Left Aligned',
                                            }}
                                        />
                                    )}
                                </CompareSection>
                            </Popup>
                        </StyleContextProvider>
                    ) : (
                        <Popup
                            active={false}
                            title={value}
                            centerPopup
                            backgroundColor={
                                parsedPopup.backgroundColor || '#ffffff'
                            }
                            mode={parsedPopup.mode}
                        >
                            <TailwindRow data={parsedPopup} />
                        </Popup>
                    )}
                </>
            ) : (
                <>
                    {value && (
                        <>
                            {isAnchor ? (
                                <a
                                    onClick={() => {
                                        document
                                            .querySelector(url)
                                            .scrollIntoView({
                                                behavior: 'smooth',
                                            });
                                    }}
                                    aria-hidden="true"
                                    role="button"
                                    tabIndex="0"
                                >
                                    {value}
                                </a>
                            ) : (
                                <Link
                                    href={url}
                                    scroll={false}
                                    target={isInternal ? '_self' : '_blank'}
                                    rel={
                                        isInternal
                                            ? ''
                                            : 'noopener noreferrer'
                                    }
                                    key={value}>

                                    {value}
                                </Link>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Hyperlink;
