import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Modules from 'components/Tailwind/Modules';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { getViewportFromWidth, parseModules } from '@viome/vnxt-nucleus';
import { PromoContext } from 'utils/context/PromoContextProvider';
import Selectors from './Selectors';

import styles from './ProductSelector.module.scss';

export default function ProductSelector({ data, settings }) {
    const { selectors, selected, interactionType } = data,
        [activeIndex, setActiveIndex] = useState(0),
        promoContext = useContext(PromoContext),
        hideProducts = promoContext.hideProducts || [],
        pageSelectorSettings = promoContext.productSelector || [],
        selectorSettings = settings['product-selector'],
        selectorMessage =
            selectorSettings?.message || pageSelectorSettings?.message || [],
        selectorDiscount =
            selectorSettings?.discount || pageSelectorSettings?.discount || {},
        selectorPricing = selectorSettings?.pricing || [],
        indexWithAddons = selectorSettings?.indexWithAddons || [], // Plans
        // Accordion Items related to plans
        accordionItemsAddons = selectorSettings?.accordionItemsAddons || null,
        // What Accordion items to hide initially
        itemsToHide = selectorSettings?.itemsToHide || [],
        selectorCode =
            selectorSettings?.discountCode ||
            pageSelectorSettings?.discountCode ||
            {},
        layoutOptions = layoutClasses(settings, null, styles, null),
        { width } = useViewport(),
        viewport = getViewportFromWidth(width),
        smallVP = viewport === 'Small' || viewport === 'Medium',
        firstItemType = !selectors[0]?.fields.shopifySubscriptionPlanProd,
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        onClick = (index) => {
            if (index != null) {
                setActiveIndex(index);
            }
        },
        interactionAttrs = (index) => {
            let currentIndex = index;
            const lastIndex = selectors.length - 1;

            if (index === activeIndex) {
                currentIndex = null;
                // We can pass '-/+' as well to adjust based on the current index
            } else if (index === '-') {
                currentIndex = activeIndex > 0 ? activeIndex - 1 : 0;
            } else if (index === '+') {
                currentIndex =
                    activeIndex < lastIndex ? activeIndex + 1 : lastIndex;
            }

            return {
                onClick: () => onClick(currentIndex),
                onKeyPress: () => onClick(currentIndex),
            };
        },
        SelectorCTA = ({ index }) => {
            const currentProduct = selectors[index],
                currentSKU = currentProduct?.fields.sku,
                selectorData = currentProduct?.fields?.productDetails?.selector;

            return (
                <div className={classNames(styles.cta, 'mb-8')}>
                    <CTA
                        data={{
                            style: 'Standard',
                            mode: 'Dark',
                            text: selectorData?.ctaText || 'Order & Subscribe',
                            pricing: selectorPricing,
                            product: currentProduct,
                            discountAmount: selectorDiscount[currentSKU],
                            discountCodeValue: selectorCode[currentSKU],
                            url: selectorData?.url,
                            salesMessage: selectorMessage[currentSKU],
                        }}
                    />
                </div>
            );
        },
        Selected = () => {
            if (smallVP) {
                return (
                    <div
                        className={classNames(
                            styles.selected,
                            activeIndex !== null && styles.active,
                            smallVP && styles.mobile,
                        )}
                    >
                        {activeIndex !== null && (
                            <Modules
                                modules={parseModules([selected[activeIndex]])}
                            />
                        )}
                    </div>
                );
            }
            return null;
        };

    if (!smallVP && hideProducts.length > 0) {
        useEffect(() => {
            // Grab all the accordion items
            const accordionItems = document
                .getElementById('#productSelector')
                ?.getElementsByClassName('moduleAccordion')[0]
                ?.firstChild?.children;

            if (accordionItems) {
                const itemMap = {},
                    // Check if the active index is a plan
                    currentIndex =
                        (indexWithAddons &&
                            indexWithAddons.indexOf(activeIndex)) ||
                        0;

                // Hide plan accordion items by default
                itemsToHide.forEach((item) => {
                    const currentItem = accordionItems[item];
                    itemMap[item] = currentItem;
                    currentItem.style.display = 'none';
                });

                // If active index is a plan, we need to unhide certain accordion items
                if (
                    currentIndex !== -1 &&
                    accordionItemsAddons &&
                    accordionItemsAddons.length - 1 >= currentIndex
                ) {
                    const accordionItemsToShow =
                        // Retrieve the accordion items to show
                        accordionItemsAddons[currentIndex];

                    if (accordionItemsToShow) {
                        accordionItemsToShow.forEach((item) => {
                            const currentItem = itemMap[item];
                            if (currentItem) {
                                currentItem.style.display = 'block';
                            }
                        });
                    }
                }
            }
        }, [activeIndex]);
    }

    return (
        selectors && (
            <div className={classNames(styles[interactionType])}>
                <div
                    className={classNames(
                        styles.selectors__wrapper,
                        layoutOptions.classes,
                    )}
                >
                    <div className={classNames(styles.selectors, 'md:mt-5')}>
                        {selectors.map((product, index) => (
                            <Selectors
                                product={product}
                                discount={selectorDiscount}
                                active={index === activeIndex}
                                index={index}
                                interactionAttrs={interactionAttrs}
                                styles={styles}
                                firstItemType={firstItemType}
                                key={`${product?.fields.name}${index}selector`}
                                hideProducts={hideProducts}
                            />
                        ))}
                    </div>
                    <SelectorCTA index={activeIndex} />
                </div>

                <Selected />
            </div>
        )
    );
}
