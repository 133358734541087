import Link from 'next/link';
import styles from './Nav.module.scss';

export default function Nav() {
    return (
        <nav className={styles.navbar}>
            <div className={styles['habit-logo']}>
                <Link href="/">
                    <img src="/logo_habit.svg" alt="Habit.com Logo" />
                </Link>
            </div>
        </nav>
    );
}
