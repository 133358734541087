import ProductLabels from './ProductLabels';
import ProductImage from './ProductImage';
import ProductDescription from './ProductDescription';
import styles from './ProductsBody.module.scss';

export default function ProductHeader({ productFields, isPlan }) {
    const {
            name,
            bundleDescription,
            productDetails = {},
            salesMessage,
            shopifySubscriptionPlanProd,
            shopifySubscriptionPlanStaging,
        } = productFields,
        currentIsPlan =
            isPlan ||
            shopifySubscriptionPlanProd ||
            shopifySubscriptionPlanStaging;

    return (
        <div className={styles.top}>
            <ProductLabels
                productName={name}
                productDetails={productDetails}
                styles={styles}
                hasSale={salesMessage}
            />
            <ProductImage
                productFields={productFields}
                styles={styles}
                isPlan={currentIsPlan}
            />
            <ProductDescription
                bundleDescription={bundleDescription}
                styles={styles}
            />
        </div>
    );
}
