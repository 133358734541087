import Image from 'components/Layout/Module/Image';

export default function ProductImages({ productFields, styles }) {
    const { shopImages } = productFields;

    return (
        <div className={styles.imageWrapper}>
            {shopImages && (
                <Image
                    data={shopImages?.fields}
                    options={{
                        priority: true,
                        quality: 100,
                    }}
                />
            )}
        </div>
    );
}
