import classNames from 'classnames';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { applyHashMarkToHexCode } from '@viome/vnxt-nucleus';
import TextRenderer from 'components/Text/Text';
import Hyperlink from './Hyperlink';
import InlineEntry from './InlineEntry';
import RenderBullet from './RenderBullet';
import InlineBlock from './InlineBlock';
import layoutClasses from '../Utils/LayoutClasses';
import styles from './InlineList.module.scss';

const listStyles = {
        'Align-Top': 'alignTop',
        'Border-Bottom': 'borderBottom',
        'Border-Top': 'borderTop',
        'Hover Links': 'hoverLinks',
        'No Bullets': 'noBullets',
        'No Margin': 'noMargins',
        'Number Bubbles': 'bubbles',
        'Topic Bubbles': 'topic',
        Thinner: 'thinner',
    },
    InlineList = (node, textStyles) => {
        const blockData = node.data.target.fields,
            {
                body,
                color,
                fontFamily,
                fontSize,
                backgroundColor,
                listStyle = [],
                bulletStyle,
                bulletColor,
                borderColor = '#000000',
                layout,
            } = blockData,
            layoutOptions = layoutClasses(
                layout,
                bulletColor,
                textStyles,
                null,
                fontSize,
            ),
            parsedColor = applyHashMarkToHexCode(color),
            parsedBulletColor = applyHashMarkToHexCode(bulletColor),
            parsedBorderColor = applyHashMarkToHexCode(borderColor),
            parsedBgColor = applyHashMarkToHexCode(backgroundColor),
            inlineStyles = {},
            listAttrs = {},
            listClasses = [],
            options = {
                renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => children,
                    [BLOCKS.UL_LIST]: (node, children) => (
                        <ul
                            style={{
                                borderColor: parsedBorderColor,
                            }}
                            {...listAttrs}
                        >
                            {children}
                        </ul>
                    ),
                    [BLOCKS.OL_LIST]: (node, children) => (
                        <ol
                            style={{
                                borderColor: parsedBorderColor,
                            }}
                            {...listAttrs}
                        >
                            {children}
                        </ol>
                    ),
                    [BLOCKS.LIST_ITEM]: (node, children) => {
                        const liListStyle = { borderColor: parsedBorderColor };
                        if (listStyle?.includes('Topic Bubbles')) {
                            liListStyle.backgroundColor = parsedBulletColor;

                            if (!parsedBulletColor) {
                                liListStyle.border = `solid 1px`;
                            }
                        }
                        return (
                            <li
                                style={{ ...liListStyle }}
                                className={styles[bulletStyle]}
                            >
                                {listStyle?.includes('Number Bubbles') && (
                                    <p
                                        style={{
                                            color:
                                                parsedBulletColor || '#000000',
                                            backgroundColor:
                                                parsedBgColor ||
                                                (parsedBulletColor
                                                    ? '#ffffff'
                                                    : '#000000'),
                                        }}
                                        className={styles.numbers}
                                    />
                                )}
                                {bulletStyle !== 'colored' && (
                                    <RenderBullet
                                        type={bulletStyle}
                                        color={bulletColor}
                                    />
                                )}
                                {children}
                            </li>
                        );
                    },
                    [BLOCKS.HR]: () => (
                        <hr
                            style={{
                                borderColor: parsedBorderColor,
                            }}
                        />
                    ),
                    [INLINES.HYPERLINK]: (node) => Hyperlink(node),
                    [INLINES.EMBEDDED_ENTRY]: (node) =>
                        InlineEntry(node, styles),
                    [BLOCKS.EMBEDDED_ASSET]: (node) =>
                        InlineBlock(node, styles),
                },
                renderText: (txt) => TextRenderer(txt, bulletStyle),
            },
            fontMap = {
                Tiempos: classNames(styles.tiempos),
            };

        if (listStyle) {
            listStyle.forEach((style) => {
                const currentStyle = listStyles[style];
                if (currentStyle) {
                    listClasses.push(styles[currentStyle]);
                }
            });
            if (bulletStyle) {
                listClasses.push(styles.customBullets);
                listClasses.push(bulletStyle);
                if (bulletStyle === 'colored') {
                    listClasses.push(styles.colored);
                }
            }
            listAttrs.className = classNames(listClasses);
        }

        if (color) {
            inlineStyles.color = parsedColor;
        }

        if (backgroundColor) {
            inlineStyles.background = parsedBgColor;
        }

        return (
            <span
                className={classNames(
                    fontFamily && fontMap[fontFamily],
                    styles.inlineList,
                    layoutOptions.classes,
                )}
                style={{
                    ...inlineStyles,
                    ...layoutOptions.styles,
                }}
            >
                {body && documentToReactComponents(body, options)}
            </span>
        );
    };

export default InlineList;
