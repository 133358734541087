import { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import classNames from 'classnames';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import RowContextProvider from 'utils/context/RowContextProvider';
import { useViewport } from 'utils/context/ViewportContextProvider';
import {
    cBoolean,
    renderSection,
    parseModules,
    InfoIcon as Info,
} from '@viome/vnxt-nucleus';
import TailwindRow from 'components/Tailwind/Row';
import Text from 'components/Tailwind/Module/Text';
import useRenderTimeout from 'components/Tailwind/Utils/useRenderTimeout';
import {
    useRenderTransition,
    layoutRenderTransitionedOpacity,
} from 'components/Tailwind/Utils/useRenderTransition';
import AutoPlayVideo from 'components/Layout/Utils/AutoPlayVideo';
import Popup from './Components/PopupGeneric';
import Modules from './Modules';
import layoutClasses from './Utils/LayoutClasses';
import MultiColumnClasses from './Utils/MultiColumnClasses';
import styles from './Row.module.scss';

const Row = ({ data }) => {
    const {
            id,
            name,
            body,
            bodyMobile = false,
            mobileColumns,
            tabletColumns,
            desktopColumns,
            columnStyles,
            columnGap,
            verticalAlignment,
            modules,
            mode,
            marginBottom,
            marginTop,
            displayWidth,
            full,
            layout,
            borderColor,
            backgroundColor,
            backgroundImageMedium,
            backgroundImageSmall,
            backgroundImageXl,
            backgroundVideo,
            isBackgroundAttached,
            videoRatio,
            heights,
            scrollable,
            renderAtDesktop,
            renderAtMobile,
            renderAtTablet,
            url,
            popup,
            __typename,
            sys,
            centerPopup,
            isHoverSection = false,
        } = data,
        rowID = __typename + (sys?.id || id),
        bodyText = body?.fields,
        moduleItems = modules?.items || parseModules(modules),
        darkMode = mode === 'Dark',
        { pixelDensity, width } = useViewport(),
        renderConfig = { renderAtMobile, renderAtTablet, renderAtDesktop },
        showSection = renderSection(renderConfig, width),
        showBody = renderSection(
            {
                renderAtMobile: true,
                renderAtTablet: !bodyMobile,
                renderAtDesktop: !bodyMobile,
            },
            width,
        ),
        hasBackgroundImg =
            backgroundImageSmall || backgroundImageMedium || backgroundImageXl,
        videoData = backgroundVideo?.fields.file,
        [hover, setHover] = useState(false),
        [active, setActive] = useState(false),
        layoutOptions = layoutClasses(
            layout,
            borderColor,
            styles,
            backgroundColor,
        ),
        inlineStyles = {},
        actionAttr = {},
        onClick = () => {
            setActive(true);
        },
        onClose = () => {
            setActive(false);
        },
        hasHoverVideo = layout?.class?.includes('hoverVideo'),
        layoutRender = layout?.render,
        renderTimeout = layoutRender?.timeout,
        renderTransition = layoutRender?.transition,
        renderTransitionTimeout = layoutRender?.transitionTimeout,
        renderTransitionOpacity = layoutRender?.transitionOpacity,
        [rendered, setRendered] = useState(!renderTimeout),
        [renderTransitioned, setRenderTransitioned] = useState(
            !renderTransitionTimeout,
        ),
        layoutOptionsClasses = layoutOptions.classes,
        parsedPopup = popup && parseModules([popup])[0],
        RowInlineStyles = dynamic(
            import('components/Layout/Utils/RowInlineStyles'),
            {
                ssr: false,
            },
        );

    if (renderTimeout) {
        useRenderTimeout(rendered, setRendered, renderTimeout);
    }

    useRenderTransition(
        rendered,
        renderTransitioned,
        setRenderTransitioned,
        renderTransitionTimeout,
    );

    layoutRenderTransitionedOpacity(
        renderTransitioned,
        renderTransition,
        renderTransitionOpacity,
        layoutOptionsClasses,
    );

    if (hasHoverVideo) {
        actionAttr.onMouseEnter = () => setHover(true);
        actionAttr.onMouseLeave = () => setHover(false);
    } else if (popup) {
        actionAttr.onClick = () => onClick();
        actionAttr.onKeyPress = () => onClick();
    }

    return (
        showSection &&
        rendered && (
            <ConditionalWrapper
                condition={cBoolean(url) || isHoverSection}
                wrapper={(nodeChildData) => {
                    if (isHoverSection) {
                        return (
                            <div className={styles.hoverWrapper}>
                                {nodeChildData}
                            </div>
                        );
                    }
                    return (
                        <Link href={url} className={styles.clickable}>
                            {nodeChildData}
                        </Link>
                    );
                }}
            >
                {(heights || videoData || hasBackgroundImg) && (
                    <RowInlineStyles
                        heights={heights}
                        rowID={rowID}
                        videoRatio={videoData && (videoRatio || 0.6)}
                        hasHoverVideo={hasHoverVideo}
                        bgs={{
                            MOBILE: backgroundImageSmall,
                            TABLET: backgroundImageMedium,
                            XL: backgroundImageXl,
                        }}
                        pixelDensity={pixelDensity}
                        layout={layout}
                    />
                )}
                <section
                    {...actionAttr}
                    id={id}
                    className={classNames(
                        styles.row,
                        'row',
                        rowID,
                        marginBottom && styles['margin-bottom'],
                        marginTop && styles['margin-top'],
                        darkMode && `${styles.dark} dark`,
                        hasBackgroundImg && styles.bg,
                        isBackgroundAttached && styles['bg--attached'],
                        backgroundVideo && styles['bg--video'],
                        cBoolean(scrollable) && styles.scrollable,
                        cBoolean(full) && styles.full,
                        'viome3--section',
                        styles[id],
                        [displayWidth, styles[displayWidth]],
                        isHoverSection && styles.hover,
                        hover && hasHoverVideo && styles.hoverVideo,
                        !isHoverSection && popup && styles.hasPopup,
                        styles[__typename],
                        layoutOptions.rowClasses,
                    )}
                    style={{ ...inlineStyles, ...layoutOptions.rowStyles }}
                >
                    {(backgroundVideo && !hasHoverVideo) ||
                        (hover && (
                            <AutoPlayVideo
                                src={`https:${videoData?.url}`}
                                cls={styles.videoBackground}
                            />
                        ))}
                    <div
                        className={classNames(
                            layoutOptions.classes,
                            styles.row__container,
                            'row__container',
                            MultiColumnClasses(
                                [
                                    ['sm', mobileColumns],
                                    ['md', tabletColumns],
                                    ['lg', desktopColumns],
                                ],
                                columnStyles,
                                columnGap,
                                verticalAlignment,
                                styles,
                            ),
                        )}
                        style={layoutOptions.styles}
                    >
                        {bodyText && showBody && (
                            <div className={styles.row__body}>
                                <Text data={bodyText} />
                            </div>
                        )}
                        <StyleContextProvider styles={styles}>
                            <RowContextProvider data={{ position: name }}>
                                <Modules
                                    modules={moduleItems}
                                    styles={styles}
                                    columns={{
                                        mobileColumns,
                                        tabletColumns,
                                        desktopColumns,
                                    }}
                                    columnStyles={columnStyles}
                                    darkMode={darkMode}
                                    wrap={false}
                                />
                            </RowContextProvider>
                        </StyleContextProvider>
                    </div>
                </section>
                {parsedPopup && (
                    <ConditionalWrapper
                        condition={active && !isHoverSection}
                        wrapper={(nodeChildData) => (
                            <Popup
                                active={false}
                                title=""
                                className="heading-5 heading"
                                icon={<Info />}
                                useCTA
                                closeState={onClose}
                                centerPopup={centerPopup}
                                backgroundColor={parsedPopup.backgroundColor}
                            >
                                {nodeChildData}
                            </Popup>
                        )}
                        elseWrapper={(nodeChildData) => {
                            if (isHoverSection) {
                                return (
                                    <div className={styles.hoverSection}>
                                        {nodeChildData}
                                    </div>
                                );
                            }
                            return null;
                        }}
                    >
                        <TailwindRow data={parsedPopup} />
                    </ConditionalWrapper>
                )}
                {marginBottom && <hr className={styles.separator} />}
            </ConditionalWrapper>
        )
    );
};

export default Row;
