import dynamic from 'next/dynamic';
import InlineText from './inlineText';
import InlineList from './inlineList';
import layoutClasses from '../Utils/LayoutClasses';

const InlineEntry = (node, styles) => {
    const blockMetaData = node.data.target.sys,
        blockType = blockMetaData.contentType?.sys?.id,
        blockData = node.data.target.fields,
        Countdown = dynamic(import('components/Shell/CountdownTimer/CountdownTimer'), {
            ssr: false,
        });

    let blockContent = <span />;

    if (blockType === 'moduleImage') {
        const { src, displayWidth, name } = blockData,
            file = src?.fields.file,
            layoutOptions = layoutClasses(blockData.layoutOptions, null, {});

        blockContent = (
            <img
                className={layoutOptions.classes}
                src={file.url}
                width={`${displayWidth}px`}
                alt={name}
                style={layoutOptions.styles}
            />
        );
    } else if (blockType === 'inlineText') {
        blockContent = InlineText(node, styles, true);
    } else if (blockType === 'inlineList') {
        blockContent = InlineList(node, styles);
    } else if (blockType === 'componentCountdownTimer') {
        blockContent = (
            <Countdown
                data={blockData}
                clockID={`${blockMetaData.id}${Math.floor(
                    Math.random() * 100,
                )}`}
                inline
            />
        );
    }
    return blockContent;
};

export default InlineEntry;
