export default function MultiColumnClasses(
    options,
    style,
    gap,
    verticalAlignment,
    styles,
) {
    const classes = [],
        // new ratios need to be added to tailwinconfig and PurgeCSS file
        columnStylesLegend = {
            '30:70': '30-70',
            '40:60': '40-60',
            '40:60 Flip @ Desktop': '40-60',
            '60:40': '60-40',
            '70:30': '70-30',
            '60:40 Flip @ Desktop': '60-40',
            ProductHero: 'ProductHero',
        };

    options.forEach((option) => {
        if (option.length > 1) {
            const viewPort = option[0],
                columns = option[1],
                printVP = viewPort === 'sm' ? '' : `${viewPort}:`;

            let retGap = gap,
                retColumns = columns;

            if (retColumns >= 1) {
                if (retColumns === 1) {
                    classes.push(`${printVP}block`);
                } else {
                    classes.push(`${printVP}grid`);
                    if (retColumns === 2 && style) {
                        if (
                            style.includes('60') ||
                            style.includes('70') ||
                            style === 'ProductHero'
                        ) {
                            retColumns = columnStylesLegend[style];
                        } else if (style === '40:40') {
                            retGap = `[20%]`;
                        }
                    }

                    classes.push(`${printVP}grid-cols-${retColumns}`);
                    if (retGap) {
                        classes.push(
                            `${printVP}gap-x-${retGap} ${printVP}gap-y-10`,
                        );
                    }

                    if (style) {
                        if (
                            style.includes('Flip @ Tablet') &&
                            viewPort === 'md'
                        ) {
                            classes.push(styles['flip--tablet']);
                        } else if (
                            style.includes('Flip @ Desktop') &&
                            viewPort === 'lg'
                        ) {
                            classes.push(styles['flip--desktop']);
                        }
                    }

                    if (viewPort !== 'lg') {
                        classes.push(styles[`${viewPort}-hasColumns`]);
                    }
                    // Max is set to column # for viewport
                    classes.push(styles[`${viewPort}-max-${columns}`]);
                    classes.push('multi');
                }
            }
        }
    });

    if (style === 'flex') {
        classes.push(`${styles.flex}`);

        classes.push(`flex`);
    } else if (classes.length > 0) {
        classes.push(styles.grid);
    }

    if (verticalAlignment) {
        classes.push(`items-${verticalAlignment.toLowerCase()}`);
    }

    return classes;
}
