/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './Grants.module.scss';

export default function Grants() {
    useEffect(() => {
        const vlsForm = document.querySelector('#vls-grants-form'),
            vlsBtn = document.querySelector('#vls-grants-form .cta'),
            /*
            studyGroup = document.querySelectorAll(
                'input[name="entry.717625224"]',
            ),
            endpoints = document.querySelector('#endpoints'),
            */
            thankyou = document.querySelector('.vls__forms--thankyou'),
            /*
            ~ @params
            ~ form (element) - form we are submitting
            ~ @description
            ~ Serializes the given form element and submits it to Google forms.
            */
            sendData = (form) => {
                const formData = new FormData(form),
                    jQuery = window.jQuery || {};

                thankyou.classList.add(styles.active);
                // vlsBtn.setAttribute('disabled', true);

                const formValues = {};
                formData.forEach((value, key) => {
                    // Reflect.has in favor of: object.hasOwnProperty(key)
                    if (!Reflect.has(formValues, key)) {
                        formValues[key] = value;
                        return;
                    }
                    if (!Array.isArray(formValues[key])) {
                        formValues[key] = [formValues[key]];
                    }
                    formValues[key].push(value);
                });

                jQuery.ajax({
                    type: 'POST',
                    url: 'https://docs.google.com/forms/d/e/1FAIpQLSfB6yFg-JyBZfkdnphJErCmEhtKqtaRFSbjwLj3XlGVF3RfGQ/formResponse',
                    data: formValues,
                    contentType: 'application/json',
                    dataType: 'jsonp',
                    complete() {
                        window.scrollTo({
                            top: thankyou.offsetTop - window.innerHeight / 2,
                            behavior: 'smooth',
                        });
                    },
                });
            };

        // Check if we are in a submitted state or not
        vlsBtn.addEventListener('click', () => {
            vlsForm.classList.add(styles['vls__forms--submitted']);
        });

        // Overwrite default Form Action
        vlsForm.addEventListener('submit', (e) => {
            e.preventDefault();
            sendData(vlsForm);
        });

        return () => {
            vlsBtn.removeEventListener('click', () => {
                vlsForm.classList.add(styles['vls__forms--submitted']);
            });

            vlsForm.removeEventListener('submit', (e) => {
                e.preventDefault();
                sendData(vlsForm);
            });
        };
    }, []);

    return (
        <div className={styles.vls__body}>
            <div className={styles.vls__container} id="program-proposal">
                <h3>Pre-proposal submission</h3>
                <p>
                    If you have any questions before or after you apply, you can
                    always email us at studies@viome.com
                    <br />
                    <br />
                    <br />
                </p>

                <div
                    className={classNames(
                        'vls__forms--thankyou',
                        styles['vls__forms--thankyou'],
                    )}
                >
                    <h4>Thank you for submitting your pre-proposal.</h4>
                    <p>
                        You will be contacted within two weeks if you are
                        considered for a full application.
                    </p>
                </div>

                <form className={styles.vls__forms} id="vls-grants-form">
                    <div className={styles['vls__forms--pair']}>
                        <div className={styles['vls__forms--field']}>
                            <label htmlFor="fname">
                                <p>First Name</p>
                            </label>
                            <input
                                type="text"
                                id="fname"
                                name="entry.887097372"
                                required=""
                            />
                        </div>
                        <div className={styles['vls__forms--field']}>
                            <label htmlFor="lname">
                                <p>Last Name </p>
                            </label>
                            <input
                                type="text"
                                id="lname"
                                name="entry.1354560237"
                                required=""
                            />
                        </div>
                    </div>

                    <label htmlFor="institution">
                        <p>Institution Name </p>
                    </label>
                    <input
                        type="text"
                        id="institution"
                        name="entry.257955215"
                        className={styles['vls__forms--field--full']}
                        required=""
                    />

                    <label htmlFor="email">
                        <p>Email </p>
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="entry.129183085"
                        className={styles['vls__forms--field--full']}
                        required=""
                    />
                    <br />
                    <br />
                    <br />

                    <div className={styles['vls__forms--pair']}>
                        <div className={styles['vls__forms--field']}>
                            <label htmlFor="number">
                                <p>Phone Number</p>
                            </label>
                            <input
                                type="tel"
                                id="number"
                                name="entry.867698753"
                                required=""
                            />
                        </div>
                        <div className={styles['vls__forms--field']}>
                            <label htmlFor="length">
                                <p>Estimated length of study</p>
                            </label>

                            <input
                                type="text"
                                id="length"
                                name="entry.990639094"
                                required=""
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className={styles['vls__forms--pair']}>
                        <div className={styles['vls__forms--field']}>
                            <label htmlFor="datestart">
                                <p>Expected Start Date</p>
                            </label>
                            <input
                                type="date"
                                id="datestart"
                                name="entry.2032027504"
                                required=""
                            />
                        </div>
                        <div className={styles['vls__forms--field']}>
                            <label htmlFor="dateend">
                                <p>Expected End Date</p>
                            </label>
                            <input
                                type="date"
                                id="dateend"
                                name="entry.20634144"
                                required=""
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    <ul className={styles['vls__forms--radio']}>
                        <li>
                            <input
                                type="radio"
                                id="prospective-option"
                                name="entry.1457373833"
                                value="Prospective"
                                required=""
                            />
                            <label htmlFor="prospective-option">
                                <div className={styles.check} />
                                <p>Prospective</p>
                            </label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                id="retrospective-option"
                                name="entry.1457373833"
                                value="Retrospective"
                                required=""
                            />
                            <label htmlFor="retrospective-option">
                                <div className={styles.check} />
                                <p>Retrospective</p>
                            </label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                id="both-option-pr"
                                name="entry.1457373833"
                                value="Both"
                                required=""
                            />
                            <label htmlFor="both-option-pr">
                                <div className={styles.check} />
                                <p>Both</p>
                            </label>
                        </li>
                    </ul>

                    <label htmlFor="participants">
                        <p>Total number of participants</p>
                    </label>
                    <br />
                    <input
                        type="number"
                        id="participants"
                        name="entry.1018421273"
                        required=""
                    />
                    <br />
                    <label>
                        <p>Study type</p>
                    </label>
                    <ul className={styles['vls__forms--radio']}>
                        <li>
                            <input
                                type="radio"
                                id="observational-option"
                                name="entry.717625224"
                                value="Observational"
                            />
                            <label htmlFor="observational-option">
                                <div className={styles.check} />
                                <p>Observational</p>
                            </label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                id="interventional-option"
                                name="entry.717625224"
                                value="Interventional"
                                required=""
                            />
                            <label
                                className={styles['interventional-option']}
                                htmlFor="interventional-option"
                            >
                                <div className={styles.check} />
                                <p>Interventional</p>
                            </label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                id="both-option"
                                name="entry.717625224"
                                value="Both"
                                required=""
                            />
                            <label htmlFor="both-option">
                                <div className={styles.check} />
                                <p>Both</p>
                            </label>
                        </li>
                    </ul>
                    <label htmlFor="endpoints">
                        <p>Endpoints (if interventional)</p>
                    </label>

                    <textarea
                        id="endpoints"
                        name="entry.1824960999"
                        rows="3"
                        maxLength="1000"
                        required=""
                    />

                    <label htmlFor="molecular">
                        <p>
                            What molecular data do you plan on collecting
                            (excluding Viome tests)?
                        </p>
                    </label>

                    <input
                        type="text"
                        id="molecular"
                        name="entry.103325149"
                        className={styles['vls__forms--field--full']}
                        required=""
                    />

                    <label htmlFor="clinical">
                        <p>What clinical data do you plan on collecting?</p>
                    </label>

                    <input
                        type="text"
                        id="clinical"
                        name="entry.444587886"
                        className={styles['vls__forms--field--full']}
                        required=""
                    />

                    <label htmlFor="dietary">
                        <p>What dietary data do you plan on collecting?</p>
                    </label>

                    <input
                        type="text"
                        id="dietary"
                        name="entry.720871352"
                        className={styles['vls__forms--field--full']}
                        required=""
                    />

                    <label htmlFor="goals">
                        <p>
                            Study goals (develop a test, a therapy…) therapy…)
                        </p>
                    </label>

                    <textarea
                        id="goals"
                        name="entry.327052169"
                        rows="3"
                        maxLength="1000"
                        required=""
                    />

                    <label htmlFor="utility">
                        <p>
                            The main purpose of VLS grants are to jointly
                            develop IP that Viome will convert into a commercial
                            product. Please describe how you envision
                            commercializing the study outcome(s).
                        </p>
                    </label>

                    <textarea
                        id="utility"
                        name="entry.549180195"
                        rows="3"
                        maxLength="1000"
                        required=""
                    />

                    <label htmlFor="description">
                        <p>Study description (limit to 1000 words) words)</p>
                    </label>

                    <textarea
                        id="description"
                        name="entry.1378341124"
                        rows="3"
                        maxLength="1000"
                        required=""
                    />
                    <br />
                    <br />

                    <input
                        className={classNames(
                            styles.CTA,
                            styles.Primary,
                            styles.Light,
                            'cta',
                        )}
                        type="submit"
                        value="Apply for VLS Grant"
                    />
                </form>
            </div>
        </div>
    );
}
