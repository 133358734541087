import React, { useRef, useEffect, useContext } from 'react';
import classNames from 'classnames';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { CarrotArrowIcon as CarrotArrow, PointerArrowIcon as PointerArrow, getViewportFromWidth, parseModules } from '@viome/vnxt-nucleus';
import Modules from 'components/Tailwind/Modules';
import Popup from 'components/Tailwind/Components/PopupGeneric';
import TailwindRow from 'components/Tailwind/Row';
import compareStyles from 'pages/compare/Compare.module.scss';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import CompareSection from 'components/Compare/CompareSection';
import { modalContext } from 'utils/context/DOMContextProvider';
import styles from './Accordion.module.scss';
import Text from '../Text';

const AfterEl = ({
        after,
        afterType,
        isActive,
        isPopup,
        onClick,
        popup,
        setModal,
    }) => {
        const parsedAfterEl = parseModules([after]);
        if (isActive || popup) {
            if (afterType === 'product') {
                const currentProduct = after.fields,
                    {
                        sku,
                        healthScoresBody,
                        shopifySubscriptionPlanProd,
                        shopifySubscriptionPlanStaging,
                    } = currentProduct,
                    isPlan =
                        shopifySubscriptionPlanProd ||
                        shopifySubscriptionPlanStaging;

                return (
                    <Popup useCTA closeState={onClick}>
                        <StyleContextProvider styles={compareStyles}>
                            <CompareSection
                                key={sku}
                                products={[after]}
                                selectedItems={[sku]}
                                sectionOrder={
                                    !isPlan
                                        ? ['title', 'optimizes', 'children']
                                        : sku === 'FCS'
                                        ? [
                                              'title',
                                              'productDetails.supplements',
                                              'productDetails.prepro',
                                              'test',
                                              'optimizes',
                                              'food',
                                              'productDetails.details',
                                          ]
                                        : [
                                              'title',
                                              'productDetails.prepro',
                                              'test',
                                              'optimizes',
                                              'food',
                                              'productDetails.details',
                                          ]
                                }
                                popup
                            >
                                {!isPlan && (
                                    <Text
                                        data={{
                                            body: healthScoresBody,
                                            style: 'Left Aligned',
                                        }}
                                    />
                                )}
                            </CompareSection>
                        </StyleContextProvider>
                    </Popup>
                );
            }
            if (isPopup === true && popup) {
                return (
                    <StyleContextProvider styles={styles}>
                        <Popup
                            closeState={() => {
                                onClick();
                                setModal(null);
                            }}
                            useCTA
                            centerPopup
                            backgroundColor={
                                parsedAfterEl[0].backgroundColor || '#ffffff'
                            }
                            mode={parsedAfterEl[0].mode}
                        >
                            <TailwindRow data={parsedAfterEl[0]} />
                        </Popup>
                    </StyleContextProvider>
                );
            }
        }
        return <Modules modules={parsedAfterEl} />;
    },
    AccordionUI = ({ style, isActive }) => {
        if (style === 'PopUp') {
            return null;
        }
        if (style.includes('Plus')) {
            return (
                <div
                    className={classNames(styles.icon)}
                    title={isActive ? 'hide' : 'show'}
                />
            );
        }
        if (style === 'ForwardArrow') {
            return <PointerArrow />;
        }
        return <CarrotArrow width={style === 'Bio' ? 20 : 15} />;
    };

export default function AccordionItem({ data, itemStyles = {} }) {
    const cardRef = useRef(null),
        { setModal } = useContext(modalContext),
        [isActive, setIsActive] = useDetectOutsideClick(
            cardRef,
            false,
            true,
            false,
        ),
        accordionData = data.fields,
        {
            beforeBody,
            afterBody,
            before,
            after,
            beforeLayout,
            afterLayout,
            isPopup,
        } = accordionData,
        afterType = after?.sys.contentType.sys.id,
        active = accordionData?.active,
        style = accordionData?.style || 'Default',
        onClick = () => {
            if (isPopup) {
                setModal(
                    <AfterEl
                        popup
                        after={after}
                        afterType={afterType}
                        isActive={isActive}
                        isPopup={isPopup}
                        onClick={onClick}
                        setModal={setModal}
                    />,
                );
            } else {
                setIsActive(!isActive);
            }
        },
        clickAttrs = {
            onClick,
            onKeyPress: onClick,
            role: 'button',
            tabIndex: 0,
            ref: cardRef,
        },
        textStyle = 'Left Aligned',
        itemClassList = [styles.accordion__item, 'accordion__item'],
        { width } = useViewport(),
        viewport = getViewportFromWidth(width);

    if (active && active.length > 0) {
        useEffect(() => {
            if (
                (viewport === 'Small' && active.includes('MOBILE')) ||
                (viewport === 'Medium' && active.includes('TABLET'))
            ) {
                setIsActive(true);
            } else if (active.includes('DESKTOP')) {
                setIsActive(true);
            }
        }, [viewport]);
    }

    return (
        <div
            className={classNames(
                itemClassList,
                afterType === 'product' && styles.popupContainer,
                styles[style],
            )}
            style={itemStyles}
        >
            <div
                className={classNames(
                    styles.before,
                    isActive && `active ${styles.active}`,
                    before ? styles.section : styles.text,
                    style === 'PlusButton' && styles.plusButton,
                )}
                {...clickAttrs}
            >
                {before ? (
                    <Modules modules={parseModules([before])} />
                ) : (
                    <Text
                        data={{
                            body: beforeBody,
                            style: textStyle,
                            layout: beforeLayout,
                        }}
                    />
                )}
                <AccordionUI style={style} isActive={isActive} />
            </div>
            <div
                className={classNames(
                    styles.after,
                    after ? styles.section : styles.text,
                    isPopup && styles.popupContainer,
                )}
            >
                {after ? (
                    <AfterEl
                        popup={false}
                        after={after}
                        afterType={afterType}
                        isActive={isActive}
                        isPopup={isPopup}
                        onClick={onClick}
                        setModal={setModal}
                    />
                ) : (
                    <Text
                        data={{
                            body: afterBody,
                            style: textStyle,
                            layout: afterLayout,
                        }}
                    />
                )}
            </div>
        </div>
    );
}
