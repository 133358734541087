import { useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
// import { useRouter } from 'next/router';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import { PromoContext } from 'utils/context/PromoContextProvider';
import { RowContext } from 'utils/context/RowContextProvider';
// import { VariantContext } from 'utils/context/VariantContextProvider';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { ENVIRONMENT, SHOPIFY } from 'utils/constants';
import useShopify from 'utils/hooks/useShopify';
import {
    base64toASCII,
    cBoolean,
    renderSection,
    formatPrice,
    checkSchedule,
    PointerArrowIcon as PointerArrow,
    PlusIcon as Plus,
    ShopPayIcon as ShopPay,
}
from '@viome/vnxt-nucleus';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import Text from 'components/Tailwind/Module/Text';
import PriceWrapper from './PriceCTA/PriceWrapper';
import styles from './CTA.module.scss';

const CTA = ({
    data: {
        mode,
        pricingMode = 'Light',
        backgroundColor,
        borderColor,
        renderAtMobile,
        renderAtTablet,
        renderAtDesktop,
        style,
        text,
        url,
        newTab,
        action,
        pricing,
        product,
        parameters,
        klaviyoFormId,
        discountAmount,
        salesMessage,
        layout,
    },
    isLegacy,
    installments = false,
}) => {
    const { width } = useViewport(),
        renderConfig = { renderAtMobile, renderAtTablet, renderAtDesktop },
        showSection = renderSection(renderConfig, width),
        promoContext = useContext(PromoContext),
        rowContext = useContext(RowContext),
        // variantData = useContext(VariantContext) || {},
        layoutOptions = layoutClasses(
            layout,
            borderColor,
            styles,
            backgroundColor,
        ),
        discountCode = promoContext?.discountCode,
        discountValue = promoContext?.discountValue,
        productFields = product?.fields,
        pricingOptions = {
            showPrice: cBoolean(pricing?.includes('Show Price')),
            showOriginal: cBoolean(pricing?.includes('Show Slashed Price')),
            showCurrency: cBoolean(pricing?.includes('Show Currency')),
            hideButton: cBoolean(pricing?.includes('Hide Button')),
            hideInstallments: cBoolean(pricing?.includes('Hide Installments')),
            right: cBoolean(pricing?.includes('To the right')),
            left: cBoolean(pricing?.includes('To the left')),
            larger: cBoolean(pricing?.includes('Larger Pricing')),
            hideSales:
                cBoolean(pricing?.includes('Hide Sales Message')) ||
                cBoolean(promoContext?.hidePromos),
            inside: cBoolean(pricing?.includes('Inside')),
            prefix: '$',
            discount:
                discountAmount || discountValue || productFields?.discount || 0,
            discountCode: discountCode || productFields?.discountCode || null,
        },
        classes = classNames(
            styles.CTA,
            'CTA',
            isLegacy ? `cta-legacy ${styles.legacy}` : 'cta',
            [styles[mode], mode],
            style,
            styles[style],
            backgroundColor && styles.bg,
            borderColor && styles.border,
            layoutOptions.classes,
            pricingOptions.showPrice && styles.showPrice,
        ),
        handleKlaviyoCustomTrigger = (e) => {
            e.preventDefault();
            const _klOnsite = window._klOnsite || [];
            _klOnsite.push(['openForm', klaviyoFormId]);
        },
        ctaPosition = rowContext?.position || 'na';
    // router = useRouter();

    let ctaURL,
        isPlan = false,
        ctaOptions = {},
        price,
        showInstallment,
        productSKU,
        renderBody = text,
        salesMessageText;

    if (productFields) {
        const productShopify =
                (ENVIRONMENT === 'development' || ENVIRONMENT === 'preview'
                    ? productFields?.shopifyStaging
                    : productFields?.shopifyProduction) &&
                useShopify(productFields),
            ctaParameters =
                parameters ||
                (pricingOptions.discountCode &&
                    `discount=${pricingOptions.discountCode}`) ||
                '';

        productSKU = productFields?.sku;

        let productId, variantId, planId, shopifyHost;

        if (ENVIRONMENT === 'production') {
            productId = base64toASCII(productFields.shopifyProduction);
            variantId = productFields?.shopifyId;
            shopifyHost = SHOPIFY.PRODUCTION_HOST;
            price = productShopify?.price || productFields?.price || 'N/A';
            planId = productFields.shopifySubscriptionPlanProd || '';
        } else {
            productId = base64toASCII(productFields.shopifyStaging);
            variantId = productFields?.shopifyIdStaging;
            shopifyHost = SHOPIFY.STAGING_HOST;
            price =
                productShopify?.price || productFields?.priceStaging || 'N/A';
            planId = productFields.shopifySubscriptionPlanStaging || '';
        }

        ctaOptions = {
            'data-name': productShopify?.name || productFields?.name,
            'data-id': productId,
            'data-sku': productSKU,
            'data-price': price,
            'data-quantity': 1,
        };

        ctaURL = `https://${shopifyHost}.viome.com/pages/addtocart?id=${variantId}&plan=${planId}${
            ctaParameters ? `&${ctaParameters.replace('?', '')}` : ''
        }`;
        /*
        const routerParams = router.query;

        if (
            variantData.country === 'US' &&
            routerParams.utm_source !== 'affiliates' &&
            routerParams.utm_medium !== 'impact' &&
            !routerParams.slug?.includes('dave')
        ) {
            if (
                !ctaPosition.includes('OTO') &&
                (productSKU === 'GI' ||
                    productSKU === 'FB' ||
                    productSKU === 'HI')
            ) {
                ctaURL = `/${productSKU.toLowerCase()}-oto?id=${variantId}&plan=${planId}${
                    ctaParameters
                        ? `&1=1&${ctaParameters.replace('?', '')}`
                        : ''
                }`;
            } else if (ctaPosition.includes('OTO')) {
                // Prevent OTO pages from upselling (circular loop prevention)
                ctaURL = `https://${shopifyHost}.viome.com/pages/addtocart?${Object.keys(
                    routerParams,
                )
                    .map((key) => `${key}=${router.query[key]}`)
                    .join('&')}`;
            }
        }
        */
        if (planId) {
            isPlan = true;
        }

        showInstallment =
            (pricingOptions.showPrice &&
                !pricingOptions.hideInstallments &&
                ['HI', 'GI', 'FB', 'CD', 'OHI'].includes(productSKU)) ||
            installments;

        salesMessageText =
            salesMessage ||
            (pricingOptions.hideSales &&
                promoContext?.salesMessage &&
                promoContext?.isPromoActiveBySchedule) ||
            (!pricingOptions.hideSales && productFields.salesMessage);
    }
    const { salesMessageStartDate, salesMessageEndDate } = productFields || {},
        isSalesMessageActiveBySchedule = checkSchedule(
            salesMessageStartDate,
            salesMessageEndDate,
        );

    if (klaviyoFormId) {
        ctaOptions.onClick = handleKlaviyoCustomTrigger;
        ctaOptions.onKeyPress = handleKlaviyoCustomTrigger;
        ctaOptions.role = 'button';
        ctaOptions.tabIndex = 0;
    }

    ctaOptions['data-position'] = ctaPosition;

    if (style === 'ForwardArrow') {
        renderBody = (
            <>
                {text}
                <PointerArrow />
            </>
        );
    }
    if (style === 'Plus') {
        renderBody = (
            <>
                <Plus />
                {text}
            </>
        );
    }

    return (
        showSection && (
            <div
                className={classNames(
                    styles.CTA__Wrapper,
                    'CTA__Wrapper',
                    styles[`${style}-wrapper`],
                    `${style}-wrapper`,
                    pricingOptions.showPrice && isPlan && styles.subscription,
                    pricingOptions.showPrice && styles.showPrice,
                    styles[mode],
                )}
            >
                {pricingOptions.showPrice && (
                    <StyleContextProvider styles={styles}>
                        <PriceWrapper
                            data={pricingOptions}
                            product={productFields}
                            isSubscription={isPlan}
                            pricingMode={pricingMode}
                        />
                    </StyleContextProvider>
                )}
                {salesMessageText && isSalesMessageActiveBySchedule && (
                    <div
                        className={classNames(
                            styles.salesMessage,
                            styles[pricingMode],
                            'salesMessage',
                        )}
                    >
                        <Text
                            data={{
                                body: salesMessageText,
                                style: 'Left Aligned',
                            }}
                        />
                    </div>
                )}
                {!pricingOptions.hideButton && (
                    <>
                        {action ? (
                            <button
                                className={classes}
                                tabIndex="0"
                                type="submit"
                            >
                                {renderBody}
                            </button>
                        ) : (
                            <ConditionalWrapper
                                condition={url || ctaURL}
                                wrapper={(nodeChildData) => (
                                    <Link
                                        href={url || ctaURL}
                                        className={classes}
                                        target={(newTab && '_blank') || ''}
                                        rel={
                                        (newTab &&
                                            'noopener noreferrer') ||
                                        ''
                                    }
                                        {...ctaOptions}
                                        style={layoutOptions.styles}
                                    >
                                        {nodeChildData}
                                    </Link>
                                )}
                                elseWrapper={(nodeChildData) => (
                                    <p
                                        className={classes}
                                        style={layoutOptions.styles}
                                    >
                                        {nodeChildData}
                                    </p>
                                )}
                            >
                                <span>{renderBody}</span>
                            </ConditionalWrapper>
                        )}
                        {showInstallment && (
                            <div
                                className={classNames(
                                    styles.showInstallment,
                                    'showInstallment',
                                    styles[pricingMode],
                                )}
                            >
                                <p>
                                    Pay in 4 interest-free payments with{' '}
                                    <span
                                        className={
                                            styles.showInstallment__image
                                        }
                                    >
                                        <ShopPay />
                                    </span>{' '}
                                    As low as{' '}
                                    <strong>
                                        $
                                        {formatPrice(
                                            price,
                                            pricingOptions.discount,
                                            4,
                                        )}
                                    </strong>
                                    <span
                                        title="The estimated payment amount excludes taxes and
                            shipping. Payment options are offered by Affirm and
                            are subject to eligibility check and might not be
                            available in all states. California residents:
                            Affirm Loan Services, LLC is licensed by the
                            Department of Business Oversight. Loans are made or
                            arranged pursuant to California Financing Law
                            license."
                                        aria-label="The estimated payment amount excludes taxes and
                            shipping. Payment options are offered by Affirm and
                            are subject to eligibility check and might not be
                            available in all states. California residents:
                            Affirm Loan Services, LLC is licensed by the
                            Department of Business Oversight. Loans are made or
                            arranged pursuant to California Financing Law
                            license."
                                    >
                                        {' *'}
                                    </span>
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    );
};

export default CTA;
