import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import { applyHashMarkToHexCode } from '@viome/vnxt-nucleus';
import styles from './PopupGeneric.module.scss';

export default function Popup({
    children,
    title,
    className,
    icon,
    useCTA = false,
    closeState,
    centerPopup,
    backgroundColor = 'ffffff',
    mode,
}) {
    const parentStyles = useContext(StyleContext) || {},
        [active, setActive] = useState(useCTA),
        background = applyHashMarkToHexCode(backgroundColor),
        onClick = () => {
            setActive(!active);
            if (closeState) {
                closeState(!active);
            }
        };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                if (active) onClick();
            }
        };

        window.addEventListener('keydown', close);
        if (active) document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
            window.removeEventListener('keydown', close);
        };
    }, [active]);

    return (
        <div className="inline-block">
            {!useCTA && (
                <a
                    role="button"
                    tabIndex="0"
                    aria-expanded={active}
                    className={classNames('flex items-center', className, [
                        styles.text,
                        parentStyles.text,
                    ])}
                    onClick={onClick}
                    onKeyPress={onClick}
                >
                    {title || 'More Info'}
                    <span className="ml-1">{icon}</span>
                </a>
            )}
            <div
                role="button"
                tabIndex="-1"
                aria-controls={module.name}
                onClick={onClick}
                onKeyPress={onClick}
                className={classNames(
                    [styles.popup, parentStyles.popup],
                    active && [styles.active, parentStyles.active],
                    centerPopup && [styles.center, parentStyles.center],
                    mode && styles[mode],
                )}
                style={{
                    backgroundColor: background,
                }}
            >
                <div
                    className={classNames([
                        styles.wrapper,
                        parentStyles.wrapper,
                    ])}
                    style={{
                        backgroundColor: background,
                    }}
                >
                    <div
                        className={classNames(styles.close)}
                        onClick={onClick}
                        onKeyPress={onClick}
                        role="button"
                        tabIndex="0"
                        aria-label="close"
                        aria-expanded={active}
                        title="close"
                    />
                    {children}
                </div>
            </div>
        </div>
    );
}
