import Link from 'next/link';
import classNames from 'classnames';
import { cBoolean, matchCSSModule } from '@viome/vnxt-nucleus';
import ConditionalWrapper from '../ConditionalWrapper';

const DivWrapperComponent = ({ data, children, sectionStyle }) => {
    const divClass = data.class,
        reverse = cBoolean(data?.reverse),
        divClassName = classNames(
            divClass,
            divClass
                ? matchCSSModule(data.class, sectionStyle)
                : 'viome-divWrapper-link',
            reverse && 'reverse',
            reverse && matchCSSModule('reverse', sectionStyle),
        ),
        title = data?.title,
        showTitle = cBoolean(data?.showTitle);

    return (
        <ConditionalWrapper
            condition={data && data.url}
            wrapper={(subChildren) => (
                <Link href={data.url} className={divClassName}>
                    {subChildren && <>{subChildren}</>}
                </Link>
            )}
            elseWrapper={(subChildren) => (
                <div className={divClassName}>
                    {subChildren && <>{subChildren}</>}
                </div>
            )}
        >
            {showTitle && <h4>{title}</h4>}
            {children}
        </ConditionalWrapper>
    );
};
export default DivWrapperComponent;
