import { A11y, Thumbs } from 'swiper';
import { Swiper } from 'swiper/react';
import styles from './Carousel.module.scss';

export default function CarouselThumbnails({
    setThumbsSwiper,
    carouselType = 'Default',
    items,
    children,
}) {
    const swiperModules = [A11y, Thumbs],
        carouselSettings = {
            Hero: {
                spaceBetween: 8,
                slidesPerView: 3.3,
                centeredSlides: true,
                centeredSlidesBounds: true,
                breakpoints: {
                    768: {
                        spaceBetween: 16,
                        slidesPerView: 4.5,
                        centeredSlides: false,
                        centeredSlidesBounds: false,
                    },
                    1024: {
                        spaceBetween: 32,
                        slidesPerView: 6,
                    },
                },
            },
            Gallery: {
                direction: 'vertical',
                spaceBetween: items.length <= 7 ? 16 : 0,
                slidesPerView: items.length > 7 ? 7.5 : 'auto',
                breakpoints: {
                    1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 16,
                    },
                    1440: {
                        slidesPerView: items.length > 5 ? 5.33 : 'auto',
                        spaceBetween: items.length <= 5 ? 16 : 0,
                    },
                },
            },
            'Solution-Gallery': {
                direction: 'vertical',
                spaceBetween: items.length <= 7 ? 16 : 0,
                slidesPerView: items.length > 7 ? 8 : 'auto',
                breakpoints: {
                    1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 16,
                    },
                    1440: {
                        slidesPerView: items.length > 7 ? 7.5 : 'auto',
                        spaceBetween: items.length <= 7 ? 16 : 0,
                    },
                },
            },
        };

    return (
        <Swiper
            onSwiper={setThumbsSwiper}
            watchSlidesProgress
            modules={swiperModules}
            className={styles.thumbs}
            {...(carouselSettings[carouselType] || carouselSettings.Default)}
            onClick={(e) => {
                e.slideTo(e.clickedIndex - 3);
            }}
        >
            {children}
        </Swiper>
    );
}
