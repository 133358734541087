import { applyHashMarkToHexCode } from '@viome/vnxt-nucleus';

export default function layoutClasses(
    settings = {},
    borderColor,
    styles = {},
    backgroundColor,
    fontSize,
) {
    const legend = {
            border: 'border',
            margin: 'm',
            padding: 'p',
            top: 't',
            right: 'r',
            bottom: 'b',
            left: 'l',
            min: 'min-h',
        },
        heights = {
            full: 'full',
            screen: 'screen',
            min: 'min',
            max: 'max',
            fit: 'fit',
        },
        renders = {
            delay: 'delay',
            duration: 'duration',
            durationFocus: 'focus:duration',
            durationHover: 'hover:duration',
            opacity: 'opacity',
            opacityFocus: 'focus:opacity',
            opacityHover: 'hover:opacity',
            transition: 'transition',
        },
        sizes = {
            'Display 1 | 60/56/40 | 1.2': {
                lg: 60,
                md: 56,
                base: 40,
                height: 1.2,
            },
            'Display 2 | 48/40/36 | 1.2': {
                lg: 48,
                md: 40,
                base: 36,
                height: 1.2,
            },
            'Headline 1 | 36/32/28 | 1.3': {
                lg: 36,
                md: 32,
                base: 28,
                height: 1.3,
            },
            'Headline 2 | 30/28/24 | 1.3': {
                lg: 30,
                md: 28,
                base: 24,
                height: 1.3,
            },
            'Headline 3 | 24/24/22 | 1.4': {
                md: 24,
                base: 22,
                height: 1.4,
            },
            'Headline 4 | 20/20/20 | 1.4': {
                base: 20,
                height: 1.4,
            },
            'Paragraph 1 | 18/18/16 | 1.5': {
                md: 18,
            },
            'Paragraph 2 | 16/16/14 | 1.5': {
                md: 16,
                base: 14,
            },
            'Caption 1 | 14/14/14 | 1.5': {
                base: 14,
            },
            'Caption 2 | 12/12/12 | 1.5': {
                base: 12,
            },
        },
        classes = new Set(),
        rowClasses = new Set(),
        rowStyles = {},
        containerStyles = {};

    Object.keys(settings).forEach((objKey) => {
        const currentSettings = settings[objKey];
        let key = objKey,
            breakpointClass = '';

        if (
            objKey === 'indicators' ||
            objKey === 'navigation' ||
            objKey === 'mode' ||
            objKey === 'module' ||
            objKey === 'product-selector' ||
            objKey === 'background-settings'
        ) {
            return;
        }

        if (objKey.includes(':')) {
            const [breakpoint, breakKey] = objKey.split(':');
            key = breakKey;
            breakpointClass = breakpoint && `${breakpoint}:`;
        }

        // Additional Classes
        if (key === 'class') {
            currentSettings.forEach((cls) => {
                classes.add(styles[cls]);
            });
        } else if (key === 'row-class') {
            currentSettings.forEach((cls) => {
                rowClasses.add(styles[cls]);
                rowClasses.add(cls);
                if (cls === 'sticky') {
                    rowClasses.add('stickyRow');
                }
            });
            // BG Style
        } else if (key === 'backgroundColor') {
            if (currentSettings.includes('linear')) {
                containerStyles.background = currentSettings;
            } else {
                containerStyles.backgroundColor =
                    applyHashMarkToHexCode(currentSettings);
            }
            /* Tailwind Flex Classes */
        } else if (key === 'flex') {
            classes.add(`${breakpointClass}flex`);
            if (currentSettings.row !== true) {
                classes.add(`${breakpointClass}flex-col`);
            } else {
                classes.add(`${breakpointClass}flex-row`);
            }
            Object.keys(currentSettings).forEach((setting) => {
                if (setting === 'justify') {
                    classes.add(
                        `${breakpointClass}justify-${currentSettings.justify}`,
                    );
                } else if (setting === 'items') {
                    classes.add(
                        `${breakpointClass}items-${currentSettings.items}`,
                    );
                } else if (setting === 'gap') {
                    classes.add(`${breakpointClass}gap-${currentSettings.gap}`);
                } else if (setting === 'wrap') {
                    classes.add(
                        `${breakpointClass}flex-${
                            currentSettings.wrap ? 'wrap' : 'nowrap'
                        }`,
                    );
                } else if (setting === 'basis') {
                    classes.add(
                        `${breakpointClass}${setting}-${currentSettings.basis}`,
                    );
                } else if (setting === 'grow') {
                    classes.add(
                        `${breakpointClass}${setting}-${currentSettings.grow}`,
                    );
                } else if (setting === 'shrink') {
                    classes.add(
                        `${breakpointClass}${setting}-${currentSettings.shrink}`,
                    );
                }
            });
            /* Tailwind Grid Classes */
        } else if (key === 'grid') {
            classes.add(`${breakpointClass}grid`);

            Object.keys(currentSettings).forEach((setting) => {
                if (setting === 'justify') {
                    classes.add(
                        `${breakpointClass}justify-${currentSettings.justify}`,
                    );
                } else if (setting === 'items') {
                    classes.add(
                        `${breakpointClass}items-${currentSettings.items}`,
                    );
                } else if (setting.includes('gap')) {
                    classes.add(
                        `${breakpointClass}${setting}-${currentSettings[setting]}`,
                    );
                } else if (setting === 'col' || setting === 'row') {
                    classes.add(
                        `${breakpointClass}grid-${setting}s-${currentSettings[setting]}`,
                    );
                }
            });
            /* Custom Font Styles */
        } else if (key === 'font') {
            Object.keys(currentSettings).forEach((setting) => {
                if (setting === 'weight') {
                    classes.add(styles[`weight-${currentSettings[setting]}`]);
                } else if (setting === 'height') {
                    containerStyles.lineHeight = currentSettings[setting];
                    classes.add(styles.resetLH);
                } else if (setting === 'color') {
                    containerStyles.color = applyHashMarkToHexCode(
                        currentSettings[setting],
                    );
                } else if (setting === 'size') {
                    classes.add(
                        styles[
                            `${breakpointClass || 'base:'}${
                                currentSettings[setting]
                            }`
                        ],
                    );
                }
            });
        } else if (key === 'render') {
            Object.keys(currentSettings).forEach((setting) => {
                const renderSetting = renders[setting],
                    renderValue = currentSettings[setting];
                if (renderSetting && renderValue >= 0) {
                    if (
                        (setting === 'duration' ||
                            setting === 'durationFocus' ||
                            setting === 'durationHover' ||
                            setting === 'delay') &&
                        renderValue > 1000
                    ) {
                        classes.add(`${renderSetting}-[${renderValue}ms]`);
                    } else {
                        classes.add(`${renderSetting}-${renderValue}`);
                    }
                } else if (renderSetting && renderValue) {
                    classes.add(`${renderSetting}-${renderValue}`);
                }
            });

            /* Tailwind Display Styles */
        } else if (key === 'display') {
            classes.add(`${breakpointClass}${currentSettings}`);

            /* Tailwind Box Model + Positioning Classes */
        } else if (!key.includes('width') && !key.includes('height')) {
            Object.keys(currentSettings).forEach((side) => {
                const currentSide = currentSettings[side];

                if (key === 'position') {
                    if (side === 'position') {
                        rowClasses.add(styles[currentSide]);
                    } else {
                        rowStyles[`${side}`] = currentSide;
                    }
                } else if (key === 'border') {
                    const border = `${
                        currentSide === 1 ? '' : `-${currentSide}`
                    }`;
                    if (side === 'style') {
                        classes.add(
                            `${breakpointClass}${legend[key]}${border}`,
                        );
                    } else if (side === 'radius') {
                        rowClasses.add(`${breakpointClass}${currentSide}`);
                        classes.add(`${breakpointClass}${currentSide}`);
                    } else if (side === 'color') {
                        containerStyles.borderColor = applyHashMarkToHexCode(
                            currentSettings.color,
                        );
                    } else if (
                        (side === 'top' || side === 'bottom') &&
                        currentSettings.top === currentSettings.bottom
                    ) {
                        classes.add(
                            `${breakpointClass}${legend[key]}-y${border}`,
                        );
                    } else if (
                        (side === 'right' || side === 'left') &&
                        currentSettings.right === currentSettings.left
                    ) {
                        classes.add(
                            `${breakpointClass}${legend[key]}-x${border}`,
                        );
                    } else {
                        classes.add(
                            `${breakpointClass}${legend[key]}-${legend[side]}${border}`,
                        );
                    }
                } else if (
                    currentSettings.top === currentSettings.bottom &&
                    currentSettings.right === currentSettings.left &&
                    currentSettings.right === currentSettings.top
                ) {
                    classes.add(
                        `${breakpointClass}${legend[key]}-${currentSide}`,
                    );
                } else if (
                    (side === 'top' || side === 'bottom') &&
                    currentSettings.top === currentSettings.bottom
                ) {
                    classes.add(
                        `${breakpointClass}${legend[key]}y-${currentSide}`,
                    );
                } else if (
                    (side === 'right' || side === 'left') &&
                    currentSettings.right === currentSettings.left
                ) {
                    classes.add(
                        `${breakpointClass}${legend[key]}x-${currentSide}`,
                    );
                } else {
                    classes.add(
                        `${breakpointClass}${legend[key]}${legend[side]}-${currentSide}`,
                    );
                }
            });
            /* Tailwind Box Dimension Classes */
        } else if (!Number.isNaN(parseInt(currentSettings, 10))) {
            const numString = currentSettings.toString();
            let styleSuffix = '';
            if (!numString.includes('px') && !numString.includes('%')) {
                styleSuffix = 'px';
            }

            if (key === 'width') {
                if (numString.includes('%')) {
                    containerStyles.width = `${currentSettings}${styleSuffix}`;
                } else {
                    containerStyles.maxWidth = `${currentSettings}${styleSuffix}`;
                }
            } else if (key === 'row-width') {
                if (numString.includes('%')) {
                    rowStyles.width = `${currentSettings}${styleSuffix}`;
                } else {
                    rowStyles.maxWidth = `${currentSettings}${styleSuffix}`;
                }
            } else if (key === 'min-height') {
                containerStyles.minHeight = `${currentSettings}${styleSuffix}`;
            } else if (numString.includes('%')) {
                containerStyles.height = `${currentSettings}${styleSuffix}`;
                rowStyles.height = `${currentSettings}${styleSuffix}`;
            } else {
                containerStyles.maxHeight = `${currentSettings}${styleSuffix}`;
            }
        } else if (key === 'min-height') {
            if (heights[settings[objKey]]) {
                classes.add(`${[legend.min]}-${heights[settings[objKey]]}`);
            }
        }
    });

    if (borderColor) {
        containerStyles.borderColor = applyHashMarkToHexCode(borderColor);
    }

    if (backgroundColor) {
        if (backgroundColor.includes('linear')) {
            rowStyles.background = backgroundColor;
        } else {
            rowStyles.backgroundColor = applyHashMarkToHexCode(backgroundColor);
            if (
                !settings?.border?.bottom &&
                !settings['md:border']?.bottom &&
                !settings['lg:border']?.bottom
            ) {
                rowStyles.marginBottom = '-1px';
            }
        }
    }

    if (fontSize) {
        const fontSettings = sizes[fontSize];

        Object.keys(fontSettings).forEach((setting) => {
            const breakpointMap = {
                base: '',
            };

            if (setting === 'height') {
                const layoutHeight = settings.font?.height;

                if (!layoutHeight) {
                    containerStyles.lineHeight = fontSettings[setting];
                    classes.add(styles.resetLH);
                }
            } else {
                const layoutSetting =
                    settings[`${breakpointMap[setting] || setting}:font`];
                if (!layoutSetting?.size) {
                    classes.add(styles[`${setting}:${fontSettings[setting]}`]);
                }
            }
        });
    }

    return {
        classes: [...classes],
        styles: containerStyles,
        rowClasses: [...rowClasses],
        rowStyles,
    };
}
