import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import Image from 'next/legacy/image';
import classNames from 'classnames';
import {
    cBoolean,
    getViewportFromWidth,
    nextImgLoader,
} from '@viome/vnxt-nucleus';
import placeholder from 'public/PlaceholderPixel.svg';
import Text from 'components/Tailwind/Module/Text';
import RowInlineStyles from 'components/Layout/Utils/RowInlineStyles';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import { useViewport } from 'utils/context/ViewportContextProvider';
import styles from './Image.module.scss';

export default function ImageComponent({ data, mp4, isBG, options }) {
    // Will need to consolidate data info from Contentful into uniform format
    // right now theres legacy, current and graphql
    let displayWidth = options?.width;

    const id = data?.sys?.id,
        imageData = data?.fields || data?.src?.fields || data,
        alt = options?.alt,
        caption = options?.caption,
        displayHeight = options?.height,
        maxWidth = options?.maxWidth,
        maxWidthMobile = options?.widthMobile,
        maxWidthTablet = options?.widthTablet,
        priority = options?.priority,
        align = options?.align || 'center',
        quality = options?.quality || 70,
        layoutOptions = layoutClasses(options?.layoutOptions || {}) || {
            styles: {},
        },
        heights = options?.layoutOptions?.heights,
        mobile = options?.mobile,
        tablet = options?.tablet,
        // Contentful options is priority ("intrinsic", "fixed", "responsive")
        // If displayWidth is defined and option above isn't passed we want to use
        // layout type 'fixed' if  DEFAULT: 'responsive'
        layout = options?.layout || (displayWidth && 'fixed') || 'responsive',
        additionalOptions = {},
        { width } = useViewport(),
        parentStyles = useContext(StyleContext),
        [currentViewport, setViewport] = useState('Small');

    let imageFile = imageData;
    if (currentViewport === 'Small' && mobile) {
        imageFile = mobile.fields;
    } else if (currentViewport === 'Medium' && tablet) {
        imageFile = tablet.fields;
    }
    if (currentViewport === 'Small' && maxWidthMobile) {
        displayWidth = maxWidthMobile;
    } else if (currentViewport === 'Medium' && maxWidthTablet) {
        displayWidth = maxWidthTablet;
    }

    const title = imageFile?.title,
        file = imageFile?.file,
        url = file?.url || data?.url || '',
        details = file?.details || data?.details,
        contentType = file?.contentType || data?.contentType,
        isImage = contentType?.includes('image');

    if (layout !== 'fill' && isImage) {
        const imageDimensions = details?.image,
            { width, height } = imageDimensions || data;

        if (displayWidth && displayHeight) {
            additionalOptions.width = displayWidth;
            additionalOptions.height = displayHeight;
        } else if (displayWidth && !displayHeight) {
            additionalOptions.width = displayWidth;
            additionalOptions.height =
                displayWidth && (height / width) * displayWidth;
        } else if (displayHeight) {
            additionalOptions.width =
                displayHeight && (width / height) * displayHeight;
            additionalOptions.height = displayHeight;
        } else {
            additionalOptions.width = width;
            additionalOptions.height = height;
        }
    }

    if (isImage) {
        if (
            (additionalOptions.width > 100 && additionalOptions.height > 100) ||
            layout === 'fill'
        ) {
            additionalOptions.placeholder = 'blur';
            additionalOptions.blurDataURL = placeholder.src;
        }
    }

    if (layout === 'fill') {
        additionalOptions.objectFit = 'cover';
        additionalOptions.objectPosition = 'center';
    }

    if (maxWidth || displayWidth) {
        layoutOptions.styles.maxWidth = `${maxWidth || displayWidth}px`;
    }

    if (url === '') {
        return null;
    }

    useEffect(() => {
        setViewport(getViewportFromWidth(width));
    }, [width]);

    return (
        <>
            {heights && (
                <RowInlineStyles heights={heights} rowID={`img${id}`} />
            )}
            <div
                className={classNames(
                    [styles.image__wrapper, parentStyles?.image__wrapper],
                    isBG && styles.bg,
                    styles[layout],
                    styles[align],
                    align,
                    'image',
                    layout === 'intrinsic' && styles.intrinsic,
                    layoutOptions.classes,
                    `img${id}`,
                )}
                style={layoutOptions.styles}
            >
                {isImage && !mp4?.fields?.file?.url ? (
                    <Image
                        src={url?.includes('http') ? url : `https:${url}`}
                        {...additionalOptions}
                        layout={layout}
                        alt={alt || title}
                        loader={nextImgLoader}
                        priority={cBoolean(priority)}
                        quality={quality}
                    />
                ) : (
                    <video autoPlay loop muted playsInline>
                        <source src={url} type={contentType} />
                        {mp4 && (
                            <source
                                src={mp4.fields?.file.url}
                                type="video/mp4"
                            />
                        )}
                    </video>
                )}
                {caption && (
                    <div
                        className={classNames([
                            styles.caption,
                            parentStyles?.caption,
                        ])}
                    >
                        <Text
                            data={{
                                body: caption,
                                style: 'Left Aligned',
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
