import { cBoolean } from '@viome/vnxt-nucleus';
import CTA from './CTA';
import PriceWrapper from './PriceWrapper';

export default function PriceCTA({ data }) {
    const productFields = data?.product?.fields,
        showCta = cBoolean(data?.showCta),
        hidePrice = cBoolean(data?.hidePrice),
        showQuantity = cBoolean(data?.showQuantity),
        ctaText = data?.ctaText,
        footer = data?.footer,
        disclaimer = data?.disclaimer,
        klaviyoFormId = data?.klaviyoFormId;

    return (
        <>
            {!hidePrice && <PriceWrapper data={data} product={productFields} />}
            {showCta && (
                <CTA
                    product={productFields}
                    showQuantity={showQuantity}
                    hidePrice={hidePrice}
                    text={ctaText}
                    footer={footer}
                    disclaimer={disclaimer}
                    klaviyoFormId={klaviyoFormId}
                />
            )}
        </>
    );
}
