import { useEffect } from 'react';
import classNames from 'classnames';
import styles from './modal.module.scss';

const Modal = ({ modalData, handleModalClose, modelStyle }) => {
    const { body } = modalData;

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleModalClose();
            }
        };

        window.addEventListener('keydown', close);
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
            window.removeEventListener('keydown', close);
        };
    }, []);

    return (
        <>
            <div className={styles.modalOverlay} />
            <div
                className={classNames(
                    styles.modalDetails,
                    modelStyle && styles[modelStyle],
                )}
            >
                <div className={styles.modalDetails__CloseButton}>
                    <a
                        onClick={() => handleModalClose()}
                        tabIndex={0}
                        role="button"
                        onKeyDown={() => handleModalClose()}
                    >
                        <img
                            src="https://images.ctfassets.net/qk4l4jfatr3e/2Byt8cdeL4nsblT9EqgvMa/2309a9e82ccf0ae503d73234ea3c5c3a/promo-modal-close.svg"
                            alt="Close"
                        />
                    </a>
                </div>
                <div className={styles.modalDetails_Body}>{body}</div>
            </div>
        </>
    );
};

export default Modal;
