import Link from 'next/link';
import classNames from 'classnames';
import styles from './Footer.module.scss';

export default function Footer() {
    return (
        <div className={styles.footer}>
            <div className={classNames(styles.header)}>
                For questions, please contact support at{' '}
                <a className={styles.contactUs} href="mailto:support@habit.com">
                    support@habit.com
                </a>
            </div>

            <div className={classNames(styles.disclaimer)}>
                <p>
                    *Disclaimer: Habit® or its affiliates do not guarantee
                    weight loss or other results or provide any medical advice.
                    Habit and its affiliates take a comprehensive approach to
                    wellness and weight loss that includes biology-based test
                    results, food and lifestyle, recommendations, precision
                    supplements, digital tools, community support, digital meal
                    plans, recipes, and goal tracking. The results you achieve
                    will depend on your individual goals and how you use this
                    information.The information provided by Habit and its
                    affiliates is for informational and educational use only.
                    The information, products, or any services offered herein
                    are not intended to be used by the customers for any
                    diagnostic purpose or as a substitute for professional
                    medical advice. You should always seek the advice of your
                    physician or other healthcare providers with any questions
                    you may have regarding diagnosis, cure, treatment,
                    mitigation, or prevention of any disease or other medical
                    condition or impairment or the status of your health.Any
                    statements made herein have not been evaluated by the Food
                    and Drug Administration.
                </p>
            </div>

            <hr />

            <div className={styles.bottom}>
                <div className={styles.copyright}>
                    Copyright©
                    <span className={styles['copyright-date']}>
                        {` ${new Date().getFullYear()}, `}
                    </span>
                    Habit LLC and its affiliates.
                </div>
                <div className={classNames(styles.links)}>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                    <Link href="/ccpa_notice">CCPA Notice</Link>
                    <Link href="/cookie-policy">Cookie Policy</Link>
                    <Link href="/terms">Terms &amp; Conditions</Link>
                    <a
                        className={classNames(
                            styles.footerLink,
                            'h-5 text-white',
                        )}
                        type="button"
                        id="accessibilityButton"
                    >
                        <div
                            className={classNames(
                                styles.accesibilityIcon,
                                'h-8 w-8',
                            )}
                        >
                            <img
                                src="https://images.ctfassets.net/qk4l4jfatr3e/6y3fLTZjUfmSCrWSYf4nUp/fd247c6d1fc773846b1a88f02c5f621e/userway-icon__1_.svg"
                                alt="userWay accessibility icon"
                                type="image/svg+xml"
                                loading="lazy"
                            />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}
