import { useEffect, useState } from 'react';
import useTabActive from './useTabActive';

const useRenderTimeout = (rendered, setRendered, renderTimeout) => {
    const tabActive = useTabActive(),
        [remainingTime, setRemainingTime] = useState(renderTimeout);

    useEffect(() => {
        if (remainingTime > 0 && tabActive) {
            const timer = setTimeout(() => {
                setRemainingTime(remainingTime - 1000);
            }, 1000);
            return () => clearTimeout(timer);
        }
        if (remainingTime <= 0) {
            setRendered(true);
        }
        return undefined;
    }, [remainingTime, tabActive]);
};

export default useRenderTimeout;
