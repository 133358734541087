import { useState } from 'react';
import classNames from 'classnames';
import { parseModules } from '@viome/vnxt-nucleus';
import Modules from 'components/Tailwind/Modules';
import Selectors from './Selectors';
import styles from './GridDropdown.module.scss';

export default function GridDropdown({ data, settings }) {
    const { selectors, selected, interactionType } = data,
        mode = settings.mode || 'dark',
        [active, setActive] = useState(null),
        onClick = (index) => {
            setActive(index);
        },
        interactionAttrs = (index) => {
            let currentIndex = index;
            const lastIndex = selectors.length - 1;

            if (index === active) {
                currentIndex = null;
                // We can pass '-/+' as well to adjust based on the current index
            } else if (index === '-') {
                currentIndex = active > 0 ? active - 1 : 0;
            } else if (index === '+') {
                currentIndex = active < lastIndex ? active + 1 : lastIndex;
            }

            return {
                onClick: () => onClick(currentIndex),
                onKeyPress: () => onClick(currentIndex),
            };
        };

    return (
        selectors && (
            <div className={classNames(styles[interactionType], styles[mode])}>
                <div className={styles.selectors__wrapper}>
                    <div
                        className={classNames(
                            styles.selectors,
                            `grid-cols-${selectors.length} gap-8`,
                        )}
                    >
                        {selectors.map((module, index) => (
                            <Selectors
                                module={parseModules([module])}
                                active={active}
                                index={index}
                                interactionAttrs={interactionAttrs}
                                styles={styles}
                                key={`${module?.fields.name}${index}selector`}
                            />
                        ))}
                    </div>
                </div>

                <div
                    className={classNames(
                        styles.selected,
                        active !== null && styles.active,
                        'mt-5',
                    )}
                >
                    {active !== null && (
                        <Modules modules={parseModules([selected[active]])} />
                    )}
                </div>
            </div>
        )
    );
}
