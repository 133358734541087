import classNames from 'classnames';
import PriceWrapper from 'components/Common/PriceCTA/PriceWrapper';
import ReactHtmlParser from 'react-html-parser';

export default function Selectors({
    index,
    product,
    discount = {},
    active,
    interactionAttrs,
    styles,
    firstItemType,
    hideProducts,
}) {
    const { fields } = product,
        { sku, name, productDetails, shopifySubscriptionPlanProd } = fields,
        attrs = {
            className: classNames(styles.selector, active && styles.active),
            ...interactionAttrs(index),
            role: 'button',
            tabIndex: '0',
            'aria-controls': sku,
            'aria-expanded': active > -1,
        },
        selectorDiscount = discount && discount[sku],
        SelectorContent = ({ selectorKey, selectorData }) => {
            if (
                selectorKey !== 'name' &&
                !active &&
                selectorData?.hideNotSelectedDetails
            )
                return null;

            if (selectorKey === 'name') {
                return (
                    <>
                        {selectorData.name}
                        <span> — </span>
                        <PriceWrapper
                            product={fields}
                            isSubscription={shopifySubscriptionPlanProd}
                            data={{
                                discount: selectorDiscount,
                                showOriginal: selectorDiscount,
                                showCurrency: true,
                            }}
                        />
                    </>
                );
            }
            if (selectorKey === 'price') {
                /*
                if (selectorDiscount) {
                    return (
                        <PriceWrapper
                            product={fields}
                            isSubscription={shopifySubscriptionPlanProd}
                        />
                    );
                }
                */
                return null;
            }

            if (selectorData[selectorKey]?.isHTML) {
                return (
                    <>
                        {ReactHtmlParser(selectorData[selectorKey].richContent)}
                    </>
                );
            }

            return <>{selectorData[selectorKey]}</>;
        };

    return (
        !hideProducts.includes(sku) && (
            <label htmlFor={name} {...attrs}>
                <input
                    className={styles.radio}
                    type="radio"
                    name="radio-button"
                    id={name}
                />
                <div>
                    {productDetails &&
                        ['name', 'subtext', 'additional', 'price'].map(
                            (key) => {
                                const selectorData =
                                    (firstItemType &&
                                        productDetails[
                                            'selector-test-variant'
                                        ]) ||
                                    productDetails.selector;

                                if (selectorData) {
                                    return (
                                        <p
                                            className={classNames(
                                                styles[key],
                                                (key === 'subtext' &&
                                                    selectorData.subTextBlack) ??
                                                    styles.subtextBlack,
                                            )}
                                        >
                                            <SelectorContent
                                                selectorKey={key}
                                                selectorData={selectorData}
                                            />
                                        </p>
                                    );
                                }
                                return null;
                            },
                        )}
                </div>
            </label>
        )
    );
}
