import classNames from 'classnames';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { applyHashMarkToHexCode } from '@viome/vnxt-nucleus';
import TextRenderer from 'components/Text/Text';
import Hyperlink from './Hyperlink';
import InlineEntry from './InlineEntry';
import RenderBullet from './RenderBullet';
import InlineBlock from './InlineBlock';
import layoutClasses from '../Utils/LayoutClasses';

const listStyles = {
        'Border-Bottom': 'borderBottom',
        'Split List': 'splitList',
        'No Bullets': 'noBullets',
        'No Margin': 'noMargins',
        'No Wrap': 'noWrap',
        'Align-Top': 'alignTop',
        'Reset Font Size': 'reset',
        Connected: 'connected',
        'Four Wide': 'fourwide',
        'Vertical At Desktop': 'verticalAtDesktop',
        'Vertical At Tablet': 'verticalAtTablet',
        'One Rem Separation': 'oneRemSeparation',
        'Topic Bubbles': 'topic',
        'Number Bubble (Non-List)': 'bubbles',
        'Number Bubbles': 'bubbles',
        'Regular Anchor': 'bubbles',
        Horizontal: 'horizontal',
    },
    InlineText = (node, styles, isInline) => {
        const blockType = node.data.target.sys.contentType.sys.id,
            blockData = node.data.target.fields,
            {
                body,
                gradient,
                color,
                fontFamily,
                fontSize,
                backgroundColor,
                listStyle = [],
                bulletStyle,
                bulletColor,
                hideAt = [],
                layout,
                isBreak,
                popup,
            } = blockData,
            layoutOptions = layoutClasses(
                layout,
                bulletColor,
                styles,
                null,
                fontSize,
            ),
            parsedBulletColor = applyHashMarkToHexCode(bulletColor),
            parsedBgColor = applyHashMarkToHexCode(backgroundColor),
            headingClass = (node, children) => (
                <span
                    className={classNames(
                        styles[node.nodeType],
                        node.nodeType,
                        styles.heading,
                        'heading',
                    )}
                >
                    {children}
                </span>
            ),
            inlineStyles = {},
            listAttrs = {},
            listClasses = [],
            options = {
                renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => children,
                    [BLOCKS.UL_LIST]: (node, children) => (
                        <ul
                            style={{
                                borderColor: parsedBulletColor,
                            }}
                            {...listAttrs}
                        >
                            {children}
                        </ul>
                    ),
                    [BLOCKS.OL_LIST]: (node, children) => (
                        <ol
                            style={{
                                borderColor: parsedBulletColor,
                            }}
                            {...listAttrs}
                        >
                            {children}
                        </ol>
                    ),
                    [BLOCKS.LIST_ITEM]: (node, children) => {
                        const liListStyle = { borderColor: parsedBulletColor };
                        if (listStyle?.includes('Topic Bubbles')) {
                            liListStyle.backgroundColor = parsedBulletColor;

                            if (!parsedBulletColor) {
                                liListStyle.border = `solid 1px`;
                            }
                        }

                        return (
                            <li
                                style={{ ...liListStyle }}
                                className={styles[bulletStyle]}
                            >
                                {listStyle?.includes('Number Bubbles') && (
                                    <p
                                        style={{
                                            color:
                                                parsedBulletColor || '#000000',
                                            backgroundColor:
                                                parsedBgColor ||
                                                (parsedBulletColor
                                                    ? '#ffffff'
                                                    : '#000000'),
                                        }}
                                        className={styles.numbers}
                                    />
                                )}
                                <RenderBullet
                                    type={bulletStyle}
                                    color={bulletColor}
                                />
                                {children}
                            </li>
                        );
                    },
                    [BLOCKS.HEADING_1]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_2]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_3]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_4]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_5]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HEADING_6]: (node, children) =>
                        headingClass(node, children),
                    [BLOCKS.HR]: () => (
                        <hr
                            style={{
                                borderColor: `${applyHashMarkToHexCode(
                                    bulletColor,
                                )}`,
                            }}
                        />
                    ),
                    [INLINES.HYPERLINK]: (node) => Hyperlink(node, popup),
                    [INLINES.EMBEDDED_ENTRY]: (node) =>
                        InlineEntry(node, styles),
                    [BLOCKS.EMBEDDED_ASSET]: (node) =>
                        InlineBlock(node, styles),
                },
                renderText: (txt) => TextRenderer(txt),
            },
            fontMap = {
                Tiempos: classNames(styles.tiempos),
            },
            showBody = hideAt && hideAt.map((vp) => `hide${vp}`);

        if (listStyle) {
            listStyle.forEach((style) => {
                const currentStyle = listStyles[style];
                if (currentStyle) {
                    listClasses.push(styles[currentStyle]);
                }
            });
            if (bulletStyle) {
                listClasses.push(styles.customBullets);
                listClasses.push(bulletStyle);
            }
            listAttrs.className = classNames(listClasses);
        }

        let ContainerElement = isInline ? 'span' : 'p',
            blockContent = <ContainerElement />;

        if (popup) {
            ContainerElement = 'div';
        }

        if (color) {
            inlineStyles.color = `${applyHashMarkToHexCode(color)}`;
        }

        if (backgroundColor) {
            inlineStyles.background = `${applyHashMarkToHexCode(
                backgroundColor,
            )}`;
        }

        if (blockType === 'inlineText') {
            blockContent = isBreak ? (
                <br className={classNames(showBody)} />
            ) : (
                <ContainerElement
                    className={classNames(
                        gradient &&
                            `${styles.gradient} ${
                                styles[`gradient--${gradient}`]
                            }`,
                        fontFamily && fontMap[fontFamily],
                        backgroundColor && styles.backgroundColor,
                        styles.inline,
                        layoutOptions.classes,
                        listStyle &&
                            (listStyle.includes('Split List') ||
                                listStyle.includes('Split Sub-List')) &&
                            styles.full,
                        listStyle &&
                            listStyle.includes('Number Bubble (Non-List)') &&
                            styles.bubble,
                        popup && styles.popup,
                        showBody,
                    )}
                    style={{
                        ...inlineStyles,
                        ...layoutOptions.styles,
                    }}
                >
                    {body && documentToReactComponents(body, options)}
                </ContainerElement>
            );
        }
        return blockContent;
    };

export default InlineText;
