import classNames from 'classnames';
import Modules from 'components/Tailwind/Modules';

export default function Selectors({
    index,
    module,
    active,
    interactionAttrs,
    styles,
}) {
    const { name } = module[0],
        attrs = {
            className: classNames(
                styles.selector,
                active === index && styles.active,
            ),
            ...interactionAttrs(index),
            role: 'button',
            tabIndex: '0',
            'aria-controls': name,
            'aria-expanded': active > -1,
        };

    return (
        <div {...attrs}>
            <Modules modules={module} />
        </div>
    );
}
