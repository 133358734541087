import { SwiperSlide } from 'swiper/react';
import { parseModules } from '@viome/vnxt-nucleus';
import { useViewport } from 'utils/context/ViewportContextProvider';
import Image from 'components/Layout/Module/Image';
import Video from 'components/Layout/Module/Video';
import Row from 'components/Tailwind/Row';
import Testimonial from '../Testimonial';
import Text from '../Text';
import styles from './Carousel.module.scss';

export default function Carousel(
    slides,
    carouselType,
    slidesPerViewDesktop,
    slidesPerViewGroup,
    empty = 0,
) {
    const parsedSlides = [],
        { width } = useViewport(),
        getWidth = (fields) => {
            if (carouselType === 'Gallery') {
                return 600;
            }
            if (carouselType === 'Solution-Gallery') {
                return 731;
            }
            return fields.displayWidth;
        };

    slides.forEach((item, index) => {
        const id = item?.sys.id,
            moduleType = item.sys.contentType.sys.id,
            { fields } = item;

        let moduleContent;

        if (moduleType === 'moduleImage') {
            moduleContent = (
                <Image
                    data={fields.src}
                    mp4={fields.mp4}
                    options={{
                        align: fields.align,
                        caption: fields.caption,
                        width: fields.displayWidth,
                        widthMobile: fields.maxWidthMobile,
                        widthTablet: fields.maxWidthTablet,
                        maxWidth: getWidth(fields),
                        layout: fields.layout,
                        priority: index > 0,
                        quality: fields.quality,
                        mobile: fields.srcMobile,
                        tablet: fields.srcTablet,
                        layoutOptions: fields.layoutOptions,
                    }}
                />
            );
        } else if (moduleType === 'moduleText') {
            moduleContent = <Text data={fields} />;
        } else if (moduleType === 'video') {
            moduleContent = <Video data={fields} />;
        } else if (moduleType === 'moduleTestimonial') {
            moduleContent = <Testimonial data={fields} />;
        } else if (
            moduleType === 'sectionSingleColumn' ||
            moduleType === 'sectionSingle20' ||
            moduleType === 'sectionMultiColumn20'
        ) {
            const parsedModule = parseModules([item])[0];
            moduleContent = <Row data={parsedModule} nested />;
        }

        parsedSlides.push(
            <SwiperSlide className="swiper-slide" key={id} data-index={index}>
                {moduleContent}
            </SwiperSlide>,
        );
    });

    if ((empty === 0 && width > 1024) || (empty > 0 && width < 1024)) {
        if (
            (slidesPerViewDesktop !== 'auto' && slidesPerViewGroup) ||
            empty > 0
        ) {
            const emptySlides =
                empty > 0
                    ? empty
                    : slidesPerViewDesktop -
                      (slides.length % slidesPerViewDesktop);

            if (emptySlides !== slidesPerViewDesktop) {
                for (let i = 0; i < emptySlides; i++) {
                    parsedSlides.push(
                        <SwiperSlide
                            className="swiper-slide"
                            key={`empty-${i}`}
                            data-index={i}
                        >
                            <div className={styles.emptySlide} />
                        </SwiperSlide>,
                    );
                }
            }
        }
    }

    return parsedSlides;
}
