import { StyleContext } from 'utils/context/StyleContextProvider';
import { useContext } from 'react';
import classNames from 'classnames';
import Image from 'components/Layout/Module/Image';
import { StarIcon as Stars } from '@viome/vnxt-nucleus';
import Text from '../Text';
import styles from './Testimonial.module.scss';

export default function Testimonial({ data }) {
    const parentStyles = useContext(StyleContext),
        { name, role, headshot, testimonial, stars, reviewType = [] } = data,
        starsArray = [];

    if (stars) {
        for (let i = 0; i < stars; i++) {
            starsArray.push(<Stars key={i} />);
        }
    }

    return (
        <section
            className={classNames(
                [styles.testimonial, parentStyles?.testimonial],
                'flex flex-col items-center justify-between gap-6 p-6 lg:p-10',
                'testimonial',
            )}
        >
            <div>
                {stars && (
                    <div
                        className={classNames(
                            [styles.stars, parentStyles?.stars],
                            'mb-6 flex justify-center lg:mb-9',
                        )}
                    >
                        {starsArray}
                    </div>
                )}
                <Text data={{ body: testimonial, style: 'Centered' }} />
            </div>
            <div
                className={classNames(
                    [styles.head, parentStyles?.head],
                    'flex flex-col items-center',
                )}
            >
                {reviewType && (
                    <ul
                        className={classNames(
                            [styles.types, parentStyles?.types],
                            'mb-6 flex flex-wrap justify-center gap-x-4 gap-y-2 lg:mb-9',
                        )}
                    >
                        {reviewType.map((type, index) => (
                            <li key={index}>
                                <p>{type}</p>
                            </li>
                        ))}
                    </ul>
                )}
                {headshot && (
                    <div
                        className={classNames(
                            [styles.headshot, parentStyles?.headshot],
                            'mb-4',
                        )}
                    >
                        <Image
                            data={headshot}
                            options={{
                                align: 'left',
                                quality: 100,
                                layout: 'fixed',
                                width: 90,
                                height: 90,
                            }}
                        />
                    </div>
                )}
                <div
                    className={classNames(
                        [styles.bio, parentStyles?.bio],
                        'text-center',
                    )}
                >
                    <p
                        className={classNames([
                            styles.name,
                            parentStyles?.name,
                        ])}
                    >
                        {name}
                    </p>
                    <p
                        className={classNames([
                            styles.role,
                            parentStyles?.role,
                            'mt-2',
                        ])}
                    >
                        {role}
                    </p>
                </div>
            </div>
        </section>
    );
}
