import classNames from 'classnames';
import Link from 'next/link';
import PriceWrapper from 'components/Common/PriceCTA/PriceWrapper';
import CTA from 'components/Tailwind/Components/CTA';

export default function ProductDetails({
    product = {},
    productDiscount,
    styles,
    isPlan,
    isBasic,
    testType,
}) {
    const productFields = product?.fields,
        { name, bundleName, bundleUrl, slug } = productFields,
        productURL = bundleUrl || `/products/${slug}`,
        CTAConfig = ['Hide Complimentary'];

    if (!isBasic) {
        CTAConfig.push('Hide Button');
    }

    return (
        <>
            <Link href={productURL} className={classNames(styles.title)}>
                <p>{bundleName || name}</p>
            </Link>
            {testType !== 'Personalized Health Products' && (
                <>
                    <div
                        className={classNames(
                            styles.detailsWrapper,
                            isPlan && styles.plan,
                        )}
                    >
                        {isPlan && <p>From</p>}
                        <PriceWrapper
                            product={productFields}
                            data={{
                                showCurrency: true,
                                discount: productDiscount,
                            }}
                            isSubscription={isPlan}
                        />
                    </div>
                    <CTA
                        data={{
                            style: 'Standard',
                            mode: 'Dark',
                            text: isPlan ? 'Order & Subscribe' : 'Order Test',
                            product,
                            pricing: CTAConfig,
                        }}
                    />
                </>
            )}
        </>
    );
}
