import Link from 'next/link';
import CTA from 'components/Common/CTA';
import Text from '../Text';
import styles from './Study.module.scss';

export default function Study({ data }) {
    return (
        <div className={styles.study}>
            <h4>{data.name}</h4>
            {data.outcome && (
                <>
                    <p className={styles.header}>Main study outcome</p>

                    <Text
                        data={{
                            body: data.outcome,
                            style: 'Left Aligned - Full Width',
                        }}
                    />
                </>
            )}
            {data.description && (
                <>
                    <p className={styles.header}>Study description</p>

                    <Text
                        data={{
                            body: data.description,
                            style: 'Left Aligned - Full Width',
                        }}
                    />
                </>
            )}
            {data.info && (
                <>
                    <p className={styles.header}>
                        Status and recruitment locations
                    </p>

                    <Text
                        data={{
                            body: data.info,
                            style: 'Left Aligned - Full Width',
                        }}
                    />
                </>
            )}
            {data.publication && (
                <>
                    <p className={styles.header}>Publications</p>
                    <Link href={data.publication}>
                        {data.publication}
                    </Link>
                </>
            )}
            {data.signUpUrl && (
                <CTA
                    data={{
                        url: data.signUpUrl,
                        mode: 'Light',
                        style: 'Primary',
                        text: 'Join the Study',
                        newTab: true,
                    }}
                />
            )}
        </div>
    );
}
