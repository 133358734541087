import { useContext, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import { PromoContext } from 'utils/context/PromoContextProvider';
import { RowContext } from 'utils/context/RowContextProvider';
import { useViewport } from 'utils/context/ViewportContextProvider';
import { ENVIRONMENT, SHOPIFY } from 'utils/constants';
import useShopify from 'utils/hooks/useShopify';
import {
    base64toASCII,
    cBoolean,
    renderSection,
    formatPrice,
    checkSchedule,
    PointerArrowIcon as PointerArrow,
    PlusIcon as Plus,
    ShopPayIcon as ShopPay,
} from '@viome/vnxt-nucleus';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import useRenderTimeout from 'components/Tailwind/Utils/useRenderTimeout';
import {
    useRenderTransition,
    layoutRenderTransitionedOpacity,
} from 'components/Tailwind/Utils/useRenderTransition';
import PriceWrapper from 'components/Common/PriceCTA/PriceWrapper';
import Text from 'components/Tailwind/Module/Text';
import Modal from 'components/Modal';
import CartUpsell from 'components/Tailwind/Components/CartUpsell';
import styles from './CTA.module.scss';

const CTA = ({
    data: {
        mode,
        pricingMode = 'Light',
        backgroundColor,
        borderColor,
        renderAtMobile,
        renderAtTablet,
        renderAtDesktop,
        style,
        text,
        url,
        newTab,
        autoWidth = false,
        action,
        pricing,
        product,
        parameters,
        klaviyoFormId,
        discountAmount,
        discountCodeValue,
        salesMessage,
        layout,
        showPriceObservations = false,
    },
    installments = false,
    position,
}) => {
    const { width } = useViewport(),
        renderConfig = { renderAtMobile, renderAtTablet, renderAtDesktop },
        showSection = renderSection(renderConfig, width),
        promoContext = useContext(PromoContext),
        rowContext = useContext(RowContext),
        layoutOptions = layoutClasses(
            layout,
            style === 'Custom' && borderColor,
            styles,
            style === 'Custom' && backgroundColor,
        ),
        discountCode = promoContext?.discountCode,
        discountValue = promoContext?.discountValue,
        productFields = product?.fields || product,
        pricingOptions = {
            showPrice: cBoolean(pricing?.includes('Show Price')),
            showCurrency: cBoolean(pricing?.includes('Show Currency')),
            hideButton: cBoolean(pricing?.includes('Hide Button')),
            hideInstallments: cBoolean(pricing?.includes('Hide Installments')),
            right: cBoolean(pricing?.includes('To the right')),
            left: cBoolean(pricing?.includes('To the left')),
            larger: cBoolean(pricing?.includes('Larger Pricing')),
            hideSales:
                cBoolean(pricing?.includes('Hide Sales Message')) ||
                cBoolean(promoContext?.hidePromos),
            inside: cBoolean(pricing?.includes('Inside')),
            prefix: '$',
            discount:
                discountAmount || discountValue || productFields?.discount || 0,
            discountCode:
                discountCodeValue ||
                discountCode ||
                productFields?.discountCode ||
                null,
        },
        classes = classNames(
            styles.cta,
            'cta',
            [styles[mode], mode],
            style,
            styles[style],
            layoutOptions.classes,
            pricingOptions.showPrice && styles.showPrice,
            pricingOptions.inside && styles.inside,
            autoWidth && styles.autoWidth,
        ),
        /**
         *  Experiment Modal
         */
        [modalActive, setModalActive] = useState(false),
        handleKlaviyoCustomTrigger = (e) => {
            e.preventDefault();
            const _klOnsite = window._klOnsite || [];
            _klOnsite.push(['openForm', klaviyoFormId]);
        },
        renderObj = layout?.render,
        renderTimeout = renderObj?.timeout,
        layoutRender = layout?.render,
        renderTransition = layoutRender?.transition,
        renderTransitionTimeout = layoutRender?.transitionTimeout,
        renderTransitionOpacity = layoutRender?.transitionOpacity,
        [rendered, setRendered] = useState(!renderTimeout),
        [renderTransitioned, setRenderTransitioned] = useState(
            !renderTransitionTimeout,
        ),
        layoutOptionsClasses = layoutOptions.classes,
        ctaPosition = rowContext?.position || position || 'na',
        router = useRouter(),
        handleModal = (e) => {
            const expKey = 'inExperiement';

            if (
                router.isReady &&
                typeof window !== 'undefined' &&
                window[expKey]
            ) {
                if (productFields && productFields?.upsell) {
                    e.preventDefault();
                    setModalActive(!modalActive);
                }
            }
        };
    /**
     *  Experiment Modal END
     */

    let ctaURL,
        isPlan = false,
        ctaOptions = {},
        price,
        showInstallment,
        productSKU,
        renderBody = text,
        salesMessageText,
        openNewTab = newTab;

    if (productFields) {
        const productShopify =
                (ENVIRONMENT === 'development' || ENVIRONMENT === 'preview'
                    ? productFields?.shopifyStaging
                    : productFields?.shopifyProduction) &&
                useShopify(productFields),
            ctaParameters =
                parameters ||
                (pricingOptions.discountCode &&
                    `discount=${pricingOptions.discountCode}`) ||
                '';

        productSKU = productFields?.sku;

        let productId, variantId, planId, shopifyHost;

        if (ENVIRONMENT === 'production') {
            productId = base64toASCII(productFields.shopifyProduction);
            variantId = productFields?.shopifyId || productShopify?.variantID;
            shopifyHost = SHOPIFY.PRODUCTION_HOST;
            price = productShopify?.price || productFields?.price || 'N/A';
            planId = productFields.shopifySubscriptionPlanProd || '';
        } else {
            productId = base64toASCII(productFields.shopifyStaging);
            variantId =
                productFields?.shopifyIdStaging || productShopify?.variantID;
            shopifyHost = SHOPIFY.STAGING_HOST;
            price =
                productShopify?.price || productFields?.priceStaging || 'N/A';
            planId = productFields.shopifySubscriptionPlanStaging || '';
        }

        ctaOptions = {
            'data-name': productShopify?.name || productFields?.name,
            'data-id': productId,
            'data-sku': productSKU,
            'data-price': price,
            'data-quantity': 1,
        };

        if (productSKU === 'CD') {
            if (ENVIRONMENT === 'production') {
                ctaURL = `https://cancerdetect.viome.com`;
            } else {
                ctaURL = `https://cancerdetect-test.viome.com`;
            }
            openNewTab = true;
        } else {
            ctaURL = `https://${shopifyHost}.viome.com/pages/addtocart?id=${variantId}&plan=${planId}${
                ctaParameters ? `&${ctaParameters.replace('?', '')}` : ''
            }`;
            /*
            const routerParams = router.query;

            if (
                variantData.country === 'US' &&
                routerParams.utm_source !== 'affiliates' &&
                routerParams.utm_medium !== 'impact' &&
                !router.asPath?.includes('dave') &&
                !router.asPath?.includes('/successhealth') &&
                !router.asPath?.includes('/exp')
            ) {
                if (
                    productSKU === 'GI' ||
                    productSKU === 'FB' ||
                    productSKU === 'HI'
                ) {
                    if (!ctaPosition.includes('OTO')) {
                        ctaURL = `/${productSKU.toLowerCase()}-oto?id=${variantId}&plan=${planId}${
                            ctaParameters
                                ? `&1=1&${ctaParameters.replace('?', '')}`
                                : ''
                        }`;
                    } else if (ctaPosition.includes('OTO')) {
                        // Prevent OTO pages from upselling (circular loop prevention)
                        ctaURL = `https://${shopifyHost}.viome.com/pages/addtocart?${Object.keys(
                            routerParams,
                        )
                            .map((key) => `${key}=${router.query[key]}`)
                            .join('&')}`;
                    }
                }
            }
            */
        }

        if (planId) {
            isPlan = true;
        }

        showInstallment =
            (pricingOptions.showPrice &&
                !pricingOptions.hideInstallments &&
                ['HI', 'GI', 'FB', 'CD', 'OHI'].includes(productSKU)) ||
            installments;

        salesMessageText =
            salesMessage ||
            (pricingOptions.hideSales && promoContext?.salesMessage) ||
            (!pricingOptions.hideSales && productFields.salesMessage);
    }
    const { salesMessageStartDate, salesMessageEndDate } = productFields || {},
        isSalesMessageActiveBySchedule = checkSchedule(
            salesMessageStartDate,
            salesMessageEndDate,
        );

    if (klaviyoFormId) {
        ctaOptions.onClick = handleKlaviyoCustomTrigger;
        ctaOptions.onKeyPress = handleKlaviyoCustomTrigger;
        ctaOptions.role = 'button';
        ctaOptions.tabIndex = 0;
    }

    ctaOptions['data-position'] = ctaPosition;

    if (style.includes('ForwardArrow')) {
        renderBody = (
            <>
                {text}
                <PointerArrow />
            </>
        );
    }
    if (style === 'Plus') {
        renderBody = (
            <>
                <Plus />
                {text}
            </>
        );
    }

    useRenderTimeout(rendered, setRendered, renderTimeout);

    useRenderTransition(
        rendered,
        renderTransitioned,
        setRenderTransitioned,
        renderTransitionTimeout,
    );

    layoutRenderTransitionedOpacity(
        renderTransitioned,
        renderTransition,
        renderTransitionOpacity,
        layoutOptionsClasses,
    );

    return (
        showSection &&
        rendered && (
            <div
                className={classNames(
                    styles.wrapper,
                    'CTA__Wrapper',
                    pricingOptions.right && styles.right,
                    pricingOptions.left && styles.left,
                    pricingOptions.larger && styles.larger,
                    pricingOptions.showPrice && isPlan && styles.subscription,
                    pricingOptions.showPrice && styles.showPrice,
                    styles[mode],
                )}
                style={layoutOptions.rowStyles}
            >
                {modalActive && (
                    <Modal
                        handleModalClose={() => setModalActive(false)}
                        modalData={{
                            body: (
                                <CartUpsell
                                    ctaUrl={url || ctaURL}
                                    product={productFields}
                                />
                            ),
                        }}
                        modelStyle="upsell"
                    />
                )}
                {pricingOptions.showPrice && (
                    <StyleContextProvider styles={styles}>
                        <PriceWrapper
                            data={pricingOptions}
                            product={productFields}
                            isSubscription={isPlan}
                            pricingMode={pricingMode}
                            showPriceObservations={showPriceObservations}
                        />
                    </StyleContextProvider>
                )}
                {salesMessageText &&
                    isSalesMessageActiveBySchedule &&
                    promoContext?.isPromoActiveBySchedule && (
                        <div
                            className={classNames(
                                styles.salesMessage,
                                styles[pricingMode],
                                'salesMessage',
                            )}
                        >
                            {typeof salesMessageText === 'string' ? (
                                <p>
                                    <b>{salesMessage}</b>
                                </p>
                            ) : (
                                <Text
                                    data={{
                                        body: salesMessageText,
                                        style: 'Left Aligned',
                                        bodyTextScaling: 'Smaller',
                                    }}
                                />
                            )}
                        </div>
                    )}
                {!pricingOptions.hideButton && (
                    <>
                        {action ? (
                            <button
                                className={classes}
                                tabIndex="0"
                                type="submit"
                            >
                                {renderBody}
                            </button>
                        ) : (
                            <ConditionalWrapper
                                condition={url || ctaURL}
                                wrapper={(nodeChildData) => (
                                    <Link
                                        href={url || ctaURL}
                                        className={classes}
                                        target={
                                                    (openNewTab && '_blank') || ''
                                                }
                                        rel={
                                                    (openNewTab &&
                                                        'noopener noreferrer') ||
                                                    ''
                                                }
                                        {...ctaOptions}
                                        style={layoutOptions.styles}
                                                /**
                                                 * Experiment Handle Modal
                                                 */
                                        onClick={(e) => handleModal(e)}
                                        onKeyDown={(e) => handleModal(e)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        {nodeChildData}
                                    </Link>
                                )}
                                elseWrapper={(nodeChildData) => (
                                    <p
                                        className={classes}
                                        style={layoutOptions.styles}
                                    >
                                        {nodeChildData}
                                    </p>
                                )}
                            >
                                <span>
                                    {renderBody}{' '}
                                    {pricingOptions.inside && (
                                        <span>
                                            $
                                            {formatPrice(
                                                price,
                                                pricingOptions.discount || 0,
                                            )}
                                        </span>
                                    )}
                                </span>
                            </ConditionalWrapper>
                        )}
                        {showInstallment && (
                            <div
                                className={classNames(
                                    styles.showInstallment,
                                    'showInstallment',
                                    styles[pricingMode],
                                )}
                            >
                                <p>
                                    Pay in 4 interest-free payments with{' '}
                                    <span
                                        className={
                                            styles.showInstallment__image
                                        }
                                    >
                                        <ShopPay />
                                    </span>{' '}
                                    As low as{' '}
                                    <strong>
                                        $
                                        {formatPrice(
                                            price,
                                            pricingOptions.discount,
                                            4,
                                        )}
                                    </strong>
                                    <span
                                        title="The estimated payment amount excludes taxes and
                            shipping. Payment options are offered by Affirm and
                            are subject to eligibility check and might not be
                            available in all states. California residents:
                            Affirm Loan Services, LLC is licensed by the
                            Department of Business Oversight. Loans are made or
                            arranged pursuant to California Financing Law
                            license."
                                        aria-label="The estimated payment amount excludes taxes and
                            shipping. Payment options are offered by Affirm and
                            are subject to eligibility check and might not be
                            available in all states. California residents:
                            Affirm Loan Services, LLC is licensed by the
                            Department of Business Oversight. Loans are made or
                            arranged pursuant to California Financing Law
                            license."
                                    >
                                        *
                                    </span>
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    );
};

export default CTA;
