/* eslint-disable camelcase */
import Head from 'next/head';
import { stripHtml } from 'string-strip-html';
import { useTheme } from 'next-themes';
import { ENVIRONMENT } from 'utils/constants';

export default function Meta({ page }) {
    const metaData = page?.metaData || page,
        metaTitle = metaData?.metaTitle || metaData?.title,
        imageData = metaData.metaImage || page.featuredImage,
        metaDescription = stripHtml(
            metaData?.metaDescription || metaData?.description || '',
        ).result,
        canonicalUrl = metaData?.canonicalUrl?.replace(
            'beta.viome.com',
            'www.viome.com',
        ),
        { resolvedTheme } = useTheme(),
        themeMode = resolvedTheme === 'dark' ? resolvedTheme : 'light',
        { no_follow, no_index } = metaData,
        displayRobots = {
            or: no_follow || no_index,
            and: no_follow && no_index,
        },
        robots = (
            <meta
                name="robots"
                content={`${no_index ? 'noindex' : ''}${
                    displayRobots.and ? ', ' : ''
                }${no_follow ? ' nofollow' : ''}`}
            />
        ),
        robotsPreview = (ENVIRONMENT === 'development' ||
            ENVIRONMENT === 'preview') && (
            <meta name="robots" content="noindex, nofollow" />
        );

    let metaImage =
        'https://images.ctfassets.net/qk4l4jfatr3e/7tPCzMqPuAgwEHIImwjvzU/d6e3c213c2d877c119a4700af75d409e/1024x1024bb.png';

    if (imageData) {
        const imageURL = imageData?.fields?.file?.url;
        if (imageURL) {
            if (imageURL.startsWith('http')) {
                metaImage = imageURL;
            } else {
                metaImage = `https:${imageURL}`;
            }
            if (page.featuredImage) {
                metaImage = `/api/img?src=${encodeURIComponent(metaImage)}`;
            }
        }
    }

    return (
        <Head>
            <title>{metaTitle} | Viome</title>
            <meta name="description" content={metaDescription} />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta
                property="og:image"
                content={decodeURIComponent(metaImage)}
                name="image"
            />
            <meta property="og:image:alt" content={metaTitle} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:locale" content="en_US" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            <meta
                name="twitter:image"
                content={decodeURIComponent(metaImage)}
            />

            <meta name="title" content={metaTitle} />
            {displayRobots.or && robots}
            {robotsPreview}
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href={canonicalUrl} hrefLang="en-us" />
            <link rel="alternate" href={canonicalUrl} hrefLang="x-default" />
            <link
                rel="alternate"
                type="application/rss+xml"
                href="https://www.habit.com/sitemap.xml"
            />
            <link
                rel="icon"
                type="image/svg+xml"
                href={`/favicons/fav-${themeMode}.svg`}
            />
            <link
                rel="alternate shortcut icon"
                href={`/favicons/favicon-${themeMode}.ico`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`/favicons/favicon-${themeMode}-32x32.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`/favicons/favicon-${themeMode}-16x16.png`}
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/favicons/apple-touch-icon.png"
            />
            <link rel="manifest" href="/favicons/manifest.json" />
            <link
                rel="mask-icon"
                href="/favicons/safari-pinned-tab.svg"
                color="#6d4ab0"
            />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-config"
                content="/favicons/browserconfig.xml"
            />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
}
