import Script from 'next/script';
import styles from './HTML.module.scss';

export default function HTMLEmbed({ data }) {
    const { embedScript } = data,
        embedBody = data.embedHTML;

    return (
        <>
            {embedScript ? <Script src={embedScript} /> : null}
            {embedBody ? (
                <div
                    className={styles.embed}
                    dangerouslySetInnerHTML={{ __html: embedBody }}
                />
            ) : null}
        </>
    );
}
