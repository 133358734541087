import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player/lazy';
import formatISODuration from 'date-fns/formatISODuration';
import useTabActive from 'components/Tailwind/Utils/useTabActive';
import styles from './Video.module.scss';

export default function Video({ data }) {
    const tabActive = useTabActive(),
        [played, setPlayed] = useState(0),
        [watchedPercent, setWatchedPercent] = useState({
            25: false,
            50: false,
            75: false,
            100: false,
        }),
        {
            autoplay,
            headline,
            url,
            videoThumbnail,
            externalVideoUrl,
            videoMetaData,
            useNarrowHeight,
            loop = false,
        } = data,
        [playing, setPlaying] = useState(autoplay),
        playIcon = (
            <svg
                className={styles.video__button}
                alt="player icon - play"
                height="60"
                width="60"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="30" cy="30" fill="#151833" opacity=".503" r="30" />
                <path
                    d="m24.98 17.5 17.787 10.9a1.875 1.875 0 0 1 0 3.198l-17.787 10.902a1.875 1.875 0 0 1 -2.855-1.598v-21.804a1.875 1.875 0 0 1 2.855-1.598z"
                    fill="#fff"
                />
            </svg>
        ),
        getThumbnail = (autoplay, videoThumbnail, externalVideoUrl) => {
            // Autoplay is false => Light mode with various thumbnail
            // options depending on Contentful values
            if (!autoplay) {
                if (videoThumbnail) {
                    const url = new URL(
                            `https:${videoThumbnail.fields?.file?.url}`,
                        ),
                        srcWithoutHost = url.pathname;

                    return `/cdn${srcWithoutHost}?w=1200`;
                }
                if (externalVideoUrl) {
                    return externalVideoUrl;
                }
                return true;
            }
            // Autoplay is true => regular mode
            return false;
        },
        playerSettings = {
            light: getThumbnail(autoplay, videoThumbnail, externalVideoUrl),
            url,
            controls: true,
            playing,
            autoPlay: autoplay,
            muted: autoplay,
            width: '100%',
            height: '100%',
            playIcon,
            loop,
        };
    let scriptTag = '';
    const scriptData = {
        name: '',
        description: '',
        thumbnailUrl: '',
        uploadDate: '',
        duration: '',
        embedUrl: '',
        height: '',
        width: '',
    };
    if (videoMetaData && videoMetaData.platform === 'vimeo') {
        const {
            name,
            description,
            pictures,
            release_time: releaseTime,
            duration,
            link,
            width,
            height,
        } = videoMetaData.video_metadata;

        scriptData.name = name;
        scriptData.description = description;
        scriptData.thumbnailUrl = pictures?.base_link;
        scriptData.uploadDate = releaseTime;
        scriptData.duration = duration;
        scriptData.embedUrl = link;
        scriptData.width = width;
        scriptData.height = height;
    }

    if (videoMetaData && videoMetaData.platform === 'youtube') {
        const { contentDetails, snippet } =
                videoMetaData.video_metadata.items &&
                videoMetaData.video_metadata.items[0],
            { title, description, thumbnails, publishedAt } = snippet,
            { duration } = contentDetails;
        scriptData.name = title;
        scriptData.description = description;
        scriptData.thumbnailUrl = thumbnails.default.url;
        scriptData.uploadDate = publishedAt;
        scriptData.duration = duration;
        scriptData.embedUrl = videoMetaData.video_url;
    }

    scriptTag = `<script type="application/ld+json">
            {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "${scriptData.name || ''}",
            "description": "${scriptData.description || ''}",
            "thumbnailUrl": "${scriptData.thumbnailUrl || ''}",
            "uploadDate": "${scriptData.uploadDate || ''}",
            "duration": "${
                formatISODuration({ seconds: scriptData.duration }) || ''
            }",
            "embedUrl": "${scriptData.embedUrl || ''}",
            "height": "${scriptData.height || ''}",
            "width": "${scriptData.width || ''}",
            "author": [
                { "name": "VIOME INC." ,
                  "@type": "Organization" }
                ]
            }
            </script>`;

    if (url.includes('vimeo')) {
        playerSettings.config = {
            vimeo: {
                playerOptions: {
                    byline: true,
                    portrait: true,
                    title: true,
                    responsive: true,
                    autoplay,
                    muted: autoplay,
                },
            },
        };
    } else if (url.includes('youtube')) {
        playerSettings.config = {
            youtube: {
                playerVars: {
                    autoplay: autoplay ? 1 : 0,
                },
            },
        };
    }
    const sendDataLayer = (event, title) => {
            if (typeof window !== 'undefined') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'video_event',
                    videoTitle: title,
                    videoValue: played,
                    videoEventType: event,
                });
            }
        },
        handleVideoEvent = (event) => {
            if (!loop) {
                sendDataLayer(event, data?.name || 'Unknown');
            }
        },
        handleOnProgress = (progress) => {
            const roundedPlayed = Math.floor(progress.played * 100);
            setPlayed(roundedPlayed);

            if (roundedPlayed >= 25 && !watchedPercent[25]) {
                handleVideoEvent(`25% watched`);
                setWatchedPercent({
                    ...watchedPercent,
                    25: true,
                });
            }
            if (roundedPlayed >= 50 && !watchedPercent[50]) {
                handleVideoEvent(`50% watched`);
                setWatchedPercent({
                    ...watchedPercent,
                    50: true,
                });
            }
            if (roundedPlayed >= 75 && !watchedPercent[75]) {
                handleVideoEvent(`75% watched`);
                setWatchedPercent({
                    ...watchedPercent,
                    75: true,
                });
            }
        },
        handleOnEnded = () => {
            handleVideoEvent(`100% watched`);
            setWatchedPercent({
                ...watchedPercent,
                25: false,
                50: false,
                75: false,
                100: false,
            });
        };
    useEffect(() => {
        setPlaying(tabActive);
    }, [tabActive]);

    const handleTabClose = () => {
        handleVideoEvent(`Closed`);
        window.removeEventListener('beforeunload', handleTabClose);
    };
    useEffect(() => {
        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, [played]);

    return (
        <div className={styles.video}>
            {headline && <h3 className={styles.video__header}>{headline}</h3>}
            <div
                className={classNames([
                    styles.video__wrapper,
                    useNarrowHeight && styles['video__wrapper-narrow'],
                ])}
            >
                <ReactPlayer
                    {...playerSettings}
                    onPlay={() => handleVideoEvent('Play')}
                    onPause={() => handleVideoEvent('Pause')}
                    onProgress={(progress) => handleOnProgress(progress)}
                    onEnded={() => handleOnEnded()}
                />
            </div>
            {videoMetaData && videoMetaData.video_metadata !== '' && (
                <div dangerouslySetInnerHTML={{ __html: scriptTag }} />
            )}
        </div>
    );
}
