import React, { useRef, useState } from 'react';
import router from 'next/router';
import classNames from 'classnames';
import SectionBody from 'containers/Sections/SectionBody';
import useDetectOutsideClick from 'lib/scripts/useDetectOutsideClick';
import { base64toASCII } from '@viome/vnxt-nucleus';
import { CTA_TEXT, ENVIRONMENT, SHOPIFY } from 'utils/constants';
import useShopify from 'utils/hooks/useShopify';
import styles from './CTA.module.scss';

// To-DO split this into 3 components
export default function CTA({
    product,
    showQuantity,
    hidePrice,
    text,
    footer,
    disclaimer,
    klaviyoFormId,
}) {
    let productId,
        variantId,
        ctaText = text,
        price,
        boldURL,
        ctaURL,
        ctaOptions = {},
        shopifyHost,
        footerClick = null;

    const useDirectCheckout = true,
        [selection, setSelection] = useState(1),
        [footerOverlay, setFooter] = useState(false),
        ctaRef = useRef(null),
        [isActive, setIsActive] = useDetectOutsideClick(ctaRef, false, true),
        productSlug = product?.slug,
        productSKU = product?.sku,
        productShopify =
            (ENVIRONMENT === 'development' || ENVIRONMENT === 'preview'
                ? product?.shopifyStaging
                : product?.shopifyProduction) && useShopify(product),
        footerFields = footer?.fields,
        footerBody = footerFields?.body || {},
        footerName = footerFields?.name,
        hasRestrictions = footerName === 'Supplements - Restrictions apply',
        disclaimerFields = disclaimer?.fields,
        disclaimerBody = disclaimerFields?.body,
        disclaimerClose = () => {
            setFooter(false);
        },
        disclaimerComponent = disclaimerBody && (
            <div className={styles.overlay__wrapper}>
                <div
                    className={styles.overlay__close}
                    onClick={disclaimerClose}
                    onKeyUp={disclaimerClose}
                    role="button"
                    tabIndex={0}
                >
                    <svg
                        height="48"
                        viewBox="0 0 49 48"
                        width="49"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m1.207 16.303h31.429m-15.007-15.5v31"
                            fill="none"
                            stroke="#151833"
                            strokeLinecap="square"
                            strokeWidth="2"
                            transform="matrix(.70710678 .70710678 -.70710678 .70710678 24.062871 .506594)"
                        />
                    </svg>
                </div>
                <SectionBody
                    data={{
                        text: disclaimerBody,
                    }}
                    bodyClass={classNames(
                        styles.overlay,
                        'overlay',
                        hasRestrictions && styles.restrictions,
                    )}
                    noContext
                />
            </div>
        ),
        onClick = () => {
            setIsActive(!isActive);
        },
        updatedSelection = (e) => {
            setSelection(e.target.value);
            setIsActive(false);
        },
        handleKlaviyoCustomTrigger = () => {
            const _klOnsite = window._klOnsite || [];
            _klOnsite.push(['openForm', klaviyoFormId]);
        },
        ctaClick = (e) => {
            if (klaviyoFormId) {
                e.preventDefault();
                handleKlaviyoCustomTrigger();
            } else if (!boldURL) {
                const href = new URL(e.target.href),
                    url = new URL(`${e.target.dataset.href}`);

                e.preventDefault();
                router.push(url.href + selection + href.search);
            }
        },
        PrintCTA = () => {
            if (footer && hasRestrictions) {
                footerClick = () => {
                    setFooter(!footerOverlay);
                };
            }

            ctaURL = useDirectCheckout
                ? `https://${shopifyHost}.viome.com/cart/${variantId}:${selection}`
                : `https://${shopifyHost}.viome.com/cart/add?id=${variantId}&quantity=${selection}`;

            if (boldURL) {
                ctaURL = boldURL;
            }

            ctaOptions = {
                href: ctaURL,
                'data-id': productId,
                'data-sku': productSKU,
                'data-price': price,
                'data-quantity': selection,
            };

            if (!ctaText) {
                if (
                    productSlug === 'gut-intelligence' ||
                    productSlug === 'health-intelligence'
                ) {
                    ctaText = CTA_TEXT.DEFAULT;
                } else {
                    ctaText = CTA_TEXT.SUBSCRIPTION;
                }
            }

            if (
                productSlug === 'gut-intelligence' ||
                productSlug === 'health-intelligence'
            ) {
                ctaOptions['data-href'] = useDirectCheckout
                    ? `https://${shopifyHost}.viome.com/cart/${variantId}:`
                    : `https://${shopifyHost}.viome.com/cart/add?id=${variantId}&quantity=`;
            }

            return (
                <>
                    <div className={classNames('button', styles.button)}>
                        <a
                            {...ctaOptions}
                            onClick={ctaClick}
                            onKeyPress={ctaClick}
                            data-product={productSlug}
                            role="button"
                            tabIndex={0}
                            className="legacy-cta"
                        >
                            {ctaText}
                        </a>
                    </div>
                    {footer && (
                        <>
                            <SectionBody
                                data={{
                                    text: footerBody,
                                }}
                                bodyClass={classNames(
                                    styles.footer,
                                    'footer',
                                    hasRestrictions && styles.restrictions,
                                )}
                                onClick={footerClick}
                                noContext
                            />
                            {footerOverlay && disclaimerComponent}
                        </>
                    )}
                </>
            );
        };

    if (ENVIRONMENT === 'production') {
        productId = base64toASCII(product.shopifyProduction);
        variantId = product?.shopifyId;
        shopifyHost = SHOPIFY.PRODUCTION_HOST;
        price = productShopify?.price || product?.price || 'N/A';
    } else {
        productId = base64toASCII(product.shopifyStaging);
        variantId = product?.shopifyIdStaging;
        shopifyHost = SHOPIFY.STAGING_HOST;
        price = productShopify?.price || product?.price || 'N/A';
    }

    return (
        <div
            className={classNames(
                styles.cta,
                'cta',
                hidePrice && styles['hide-price'],
                hidePrice && 'hide-price',
            )}
        >
            {showQuantity && (
                <div className={styles.quantity}>
                    <div
                        className={classNames(
                            styles.selected,
                            isActive && `active ${styles.active}`,
                        )}
                        onClick={onClick}
                        onKeyPress={onClick}
                        role="button"
                        tabIndex={0}
                    >
                        Qty:&nbsp;
                        <span>{selection}</span>
                        <svg
                            height="8"
                            viewBox="0 0 12 8"
                            width="12"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m117.153 946.5v6h6"
                                fill="none"
                                stroke="#1f4855"
                                strokeLinecap="square"
                                strokeWidth="2"
                                transform="matrix(.70710678 -.70710678 .70710678 .70710678 -750.20589 -584.936888)"
                            />
                        </svg>
                    </div>
                    <ol ref={ctaRef}>
                        {[...Array(5)].map((e, i) => (
                            <li
                                aria-selected={i + 1}
                                key={`${Math.random()}`}
                                onClick={updatedSelection}
                                onKeyPress={onClick}
                                role="option"
                                tabIndex={0}
                                value={i + 1}
                            >
                                {i + 1}
                            </li>
                        ))}
                    </ol>
                </div>
            )}
            <PrintCTA />
        </div>
    );
}
