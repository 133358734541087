import useSWR from 'swr';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PriceWrapper from 'components/Common/PriceCTA/PriceWrapper';
import Image from 'components/Layout/Module/Image';
import dynamic from 'next/dynamic';

export default function CartUpsell({ product, ctaUrl }) {
    const {
            title,
            icon,
            takeActionText,
            subscribeText,
            yesButtonText,
            noButtonText,
            alternativeProductCtaUrl,
            alternativeProductImage,
            alternativeProductSlug,
            alternativeProductDescription,
            alternativeProductTitle,
            mainProductDescription,
        } = product?.cartUpsellModal?.fields || {},
        fetcher = (url, product) =>
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({ product }),
            })
                .then((res) => res.json())
                .catch((e) => {
                    console.error({ error: e });
                }),
        { data: alternativeProduct, isLoading } = useSWR(
            [`/api/product`, alternativeProductSlug],
            ([url, product]) => fetcher(url, product),
        ),
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        { productImage, name } = product,
        alternativeProductData = alternativeProduct?.data,
        salesMessage = alternativeProductData?.salesMessage;

    return (
        <div className="relative -mt-16">
            <div className="mb-6 flex items-center gap-4 align-middle">
                <div className="w-10">
                    <Image data={icon} />
                </div>
                <div className="font-sans text-3xl">{title}</div>
            </div>
            <div className="mb-10 border px-6 pb-4 pt-6">
                <div className="flex flex-wrap justify-between gap-x-10 md:flex-nowrap">
                    <div className="basis-5/12 md:basis-auto">
                        <Image
                            data={productImage}
                            options={{
                                width: 100,
                                height: 100,
                                quality: 100,
                                layout: 'fixed',
                            }}
                        />
                    </div>
                    <div className="flex basis-5/12 flex-col text-right md:basis-auto md:text-left">
                        <div className="font-bold">{name}</div>
                        <div className="mb-3 text-gray-600">Quantity: 1</div>
                        {mainProductDescription && (
                            <div className="text-gray-600">
                                {mainProductDescription}
                            </div>
                        )}
                    </div>
                    <div className="ml-auto basis-full text-right md:basis-auto md:text-left">
                        <PriceWrapper
                            product={product}
                            pricingMode="Light"
                            showTax={false}
                            showDiscount
                        />
                    </div>
                </div>
            </div>
            <div className="text-3xl text-[#009E82]">{takeActionText}</div>
            {!isLoading && alternativeProductData && (
                <div className="= border-[#009E8 mb-10 border-2">
                    <div className="flex flex-wrap justify-between gap-x-10 p-6 md:flex-nowrap">
                        <div className="basis-5/12 md:basis-auto">
                            {alternativeProductData?.sku && (
                                <Image
                                    data={
                                        alternativeProductData?.productImage ||
                                        alternativeProductImage
                                    }
                                    options={{
                                        width: 100,
                                        height: 100,
                                        quality: 100,
                                        layout: 'fixed',
                                    }}
                                />
                            )}
                        </div>
                        <div className="flex basis-5/12 flex-col text-right md:basis-auto md:text-left">
                            <p className="font-bold">
                                {alternativeProductTitle ??
                                    alternativeProductData?.name}
                            </p>
                            <div>
                                {documentToReactComponents(
                                    alternativeProductDescription,
                                )}
                            </div>
                        </div>
                        <div className="ml-auto basis-full text-right md:basis-auto md:text-left">
                            <PriceWrapper
                                product={alternativeProductData}
                                isSubscription
                                pricingMode="Light"
                                showTax={false}
                                showDiscount={false}
                            />
                        </div>
                    </div>
                    <div className="promo bg-[#009E82] p-2 text-white">
                        {salesMessage
                            ? documentToReactComponents(salesMessage.json)
                            : subscribeText}
                    </div>
                </div>
            )}
            <div className="flex justify-between gap-4">
                {alternativeProductCtaUrl && (
                    <div className="w-1/2">
                        <CTA
                            data={{
                                style: 'Secondary',
                                mode: 'Dark',
                                text: yesButtonText,
                                product: alternativeProductData,
                                layout: {
                                    class: ['wide'],
                                },
                                pricing: ['Hide Sales Message'],
                            }}
                        />
                    </div>
                )}
                {ctaUrl && (
                    <div className="w-1/2">
                        <CTA
                            data={{
                                style: 'Standard',
                                mode: 'Light',
                                text: noButtonText,
                                url: ctaUrl,
                                layout: {
                                    class: ['wide'],
                                },
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
