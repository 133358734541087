import { getBackgroundFile } from '@viome/vnxt-nucleus';
import { VIEWPORT_MEDIA_QUERIES } from 'utils/constants';

const RowInlineStyles = ({
    heights = {},
    rowID,
    videoRatio,
    hasHoverVideo,
    bgs = {},
    pixelDensity,
    // layout = {},
}) => {
    const viewports = Object.keys(VIEWPORT_MEDIA_QUERIES),
        hasBackgroundImg = bgs.XL || bgs.TABLET || bgs.MOBILE,
        // backgroundSettings = layout['background-settings'] || {},
        formatRatio = (ratio) => {
            if (ratio < 1) {
                return ratio * 100;
            }
            return ratio;
        },
        setBG = (viewport, bg) => {
            const imageData =
                    bg ||
                    // Select the next biggest size if viewport size isn't available
                    bgs.XL ||
                    bgs.TABLET ||
                    bgs.MOBILE,
                imageFile = imageData?.fields?.file,
                parsedURL = getBackgroundFile(
                    imageFile,
                    pixelDensity,
                    viewport,
                );

            if (parsedURL) {
                return parsedURL.replace('q=60', 'q=100');
            }

            return null;
        };

    let rowStyles = '';

    viewports.forEach((viewport) => {
        const currentBG =
            hasBackgroundImg && setBG(viewport, bgs[viewport], false);

        rowStyles += `@media only screen and (${
            VIEWPORT_MEDIA_QUERIES[viewport]
        }) {
    .${rowID} {
        ${typeof heights[viewport] === 'string' ? 'padding-top' : `height`}: ${
            heights[viewport]
                ? `${heights[viewport]}${
                      typeof heights[viewport] === 'string' ? '' : `px`
                  }`
                : `auto`
        } !important;
        ${currentBG ? `background-image: ${currentBG};` : ''}
    }
}\n`;
    });

    if (videoRatio && !hasHoverVideo) {
        rowStyles += `.${rowID} {
                padding: 0 0 ${formatRatio(videoRatio)}% 0 !important;
        }\n`;
    }
    return <style suppressHydrationWarning>{rowStyles.toString()}</style>;
};

export default RowInlineStyles;
