import { useEffect } from 'react';

export const useRenderTransition = (
        rendered,
        renderTransitioned,
        setRenderTransitioned,
        renderTransitionTimeout,
    ) => {
        useEffect(() => {
            if (
                rendered &&
                !renderTransitioned &&
                !Number.isNaN(parseInt(renderTransitionTimeout, 10))
            ) {
                setTimeout(() => {
                    setRenderTransitioned(true);
                }, renderTransitionTimeout);
            }
        }, [
            rendered,
            renderTransitioned,
            setRenderTransitioned,
            renderTransitionTimeout,
        ]);
    },
    layoutRenderTransitionedOpacity = (
        renderTransitioned,
        renderTransition,
        renderTransitionOpacity,
        layoutOptionsClasses,
    ) => {
        if (renderTransitioned && renderTransition === 'opacity') {
            for (let i = 0; i < layoutOptionsClasses.length; i++) {
                if (layoutOptionsClasses[i].match(/^opacity-+/i)) {
                    layoutOptionsClasses.splice(i, 1);
                }
            }
            if (renderTransitionOpacity) {
                layoutOptionsClasses.push(
                    `${renderTransition}-${renderTransitionOpacity}`,
                );
            }
        }
    };

export default useRenderTransition;
