import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import TextTransition, { presets } from 'react-text-transition';
import ConditionalWrapper from 'utils/ConditionalWrapper';
import { parseInline , cBoolean, matchCSSModule } from '@viome/vnxt-nucleus';
import Card from 'components/Common/Card';
import PriceCTA from 'components/Common/PriceCTA/PriceCTA';
import CTA from 'components/Common/CTA';
import ExpandingText from 'components/Common/ExpandingText';
import Image from 'components/Common/Image';
import { StyleContext } from 'utils/context/StyleContextProvider';
import SectionModules from './SectionModules';

export default function SectionBody({
    data,
    sectionStyles,
    parsedClasses,
    moduleWrapper,
    bodyClass,
    onClick,
    noContext,
    prebody,
    children,
    moduleParser,
    disableMedia,
}) {
    const stylesContext = noContext
            ? sectionStyles
            : useContext(StyleContext) || sectionStyles,
        text = data?.text || data?.body || null,
        media = data?.media || null,
        modules = data?.modules || null,
        settings = data?.settings || null,
        bodyCSS = settings?.body?.css || {},
        sectionStyle = stylesContext || {},
        useHeadingElements = cBoolean(data?.useHeadingElements),
        removeEmptyP = (nodeChildren) =>
            nodeChildren.join('').trim() !== '' && <p>{nodeChildren}</p>,
        formatP = (node, nodeChildren) => {
            let hasAnimation;

            const parsedNodeChildren = nodeChildren.map((el) => {
                if (el?.props?.className?.indexOf('animate') > -1) {
                    hasAnimation = true;
                } else {
                    return typeof el === 'string' ? (
                        <p key={Math.random()}>{el.trim()}</p>
                    ) : (
                        <p key={Math.random()}>{el}</p>
                    );
                }
                return el;
            });

            return hasAnimation
                ? parsedNodeChildren
                : removeEmptyP(nodeChildren);
        },
        animate = (textValue) => {
            const doAnimate = settings?.animate;

            if (doAnimate) {
                const [index, setIndex] = useState(0),
                    animationText = doAnimate?.text,
                    animationTextLength = animationText?.length,
                    animationOptions = doAnimate?.options;

                useEffect(() => {
                    const intervalId = setInterval(
                        // eslint-disable-next-line no-shadow
                        () => setIndex((index) => index + 1),
                        animationOptions?.delay || 3000, // every 3 seconds
                    );
                    return () => clearTimeout(intervalId);
                }, []);

                return (
                    <TextTransition
                        text={animationText[index % animationTextLength]}
                        springConfig={presets.gentle}
                        className={classNames(sectionStyle.animate, 'animate')}
                        direction={animationOptions?.direction}
                        inline={animationOptions?.inline}
                    />
                );
            }
            return <u>{textValue}</u>;
        },
        headingClass = (node, nodeChildren) => {
            const nodeClass = classNames(
                    'heading',
                    node.nodeType,
                    sectionStyle && sectionStyle.heading,
                    sectionStyle && sectionStyle[node.nodeType],
                ),
                nodetypeElementMap = {
                    'heading-1': 'h1',
                    'heading-2': 'h2',
                    'heading-3': 'h3',
                    'heading-4': 'h4',
                    'heading-5': 'h5',
                    'heading-6': 'h6',
                },
                HeadingElement = nodetypeElementMap[node.nodeType];

            return (
                <ConditionalWrapper
                    condition={
                        HeadingElement &&
                        (node.nodeType === 'heading-1' || useHeadingElements)
                    }
                    wrapper={(nodeChildData) => (
                        <HeadingElement className={nodeClass}>
                            {nodeChildData}
                        </HeadingElement>
                    )}
                    elseWrapper={(nodeChildData) => (
                        <p className={nodeClass}>{nodeChildData}</p>
                    )}
                >
                    {nodeChildren}
                </ConditionalWrapper>
            );
        },
        inlineBlock = (node) => {
            const parsedInlineBlock = parseInline(node),
                inlineMedia = parsedInlineBlock?.media,
                blockType = parsedInlineBlock?.blockType,
                width = parsedInlineBlock?.width,
                inlineStyles = {
                    width: width ? `${width}%` : null,
                };

            let blockContent = <div />;
            if (blockType === 'media') {
                const mediaType = parsedInlineBlock?.mediaType || '',
                    mediaFields = inlineMedia?.fields,
                    mediaFile = mediaFields && mediaFields.file;

                if (mediaType.indexOf('image') > -1) {
                    const imageDimensions =
                            mediaFile && mediaFile.details.image,
                        imageWidth = imageDimensions.width,
                        inlineOptions = settings?.overrides?.inline || {},
                        useMedia = inlineOptions?.useMedia === true;

                    blockContent = (
                        <div
                            className={classNames(
                                sectionStyle && sectionStyle.image,
                                'image',
                            )}
                            style={{ maxWidth: `${imageWidth}px` }}
                        >
                            <Image
                                image={{
                                    images: useMedia ? media : [inlineMedia],
                                    options: settings,
                                    inlineOptions,
                                }}
                                max={840}
                            />
                        </div>
                    );
                }
            } else if (blockType === 'cardModule') {
                const cardFields = inlineMedia?.fields,
                    cardTitle = cardFields?.title;

                blockContent = <Card key={cardTitle} data={cardFields} />;
            } else if (blockType === 'expandingTextModule') {
                const moduleFields = inlineMedia?.fields,
                    moduleTitle = moduleFields?.title;

                blockContent = (
                    <ExpandingText key={moduleTitle} data={moduleFields} />
                );
            } else if (blockType === 'price') {
                const priceFields = inlineMedia?.fields;

                blockContent = <PriceCTA data={priceFields} />;
            } else if (blockType === 'textBlock') {
                const moduleFields = inlineMedia?.fields,
                    richTextData = moduleFields?.body,
                    className = moduleFields?.classes;

                delete inlineStyles.width;

                blockContent = (
                    <div
                        className={classNames(
                            matchCSSModule(className, stylesContext),
                            'textBlock__outer',
                            stylesContext.textBlock__outer,
                        )}
                    >
                        <div
                            className={classNames(
                                'textBlock__inner',
                                stylesContext.textBlock__inner,
                            )}
                        >
                            {
                                // eslint-disable-next-line no-use-before-define
                                documentToReactComponents(richTextData, options)
                            }
                        </div>
                    </div>
                );
            } else if (blockType === 'componentCta') {
                const blockData = node.data.target.fields;
                blockContent = <CTA data={blockData} isLegacy />;
            }

            return (
                <div
                    className={classNames(sectionStyle.inline, 'inline')}
                    style={inlineStyles}
                >
                    {blockContent}
                </div>
            );
        },
        options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, nodeChildren) =>
                    formatP(node, nodeChildren),
                [BLOCKS.HEADING_1]: (node, nodeChildren) =>
                    headingClass(node, nodeChildren),
                [BLOCKS.HEADING_2]: (node, nodeChildren) =>
                    headingClass(node, nodeChildren),
                [BLOCKS.HEADING_3]: (node, nodeChildren) =>
                    headingClass(node, nodeChildren),
                [BLOCKS.HEADING_4]: (node, nodeChildren) =>
                    headingClass(node, nodeChildren),
                [BLOCKS.HEADING_5]: (node, nodeChildren) =>
                    headingClass(node, nodeChildren),
                [BLOCKS.HEADING_6]: (node, nodeChildren) =>
                    headingClass(node, nodeChildren),
                [BLOCKS.EMBEDDED_ASSET]: (node) => inlineBlock(node),
                [BLOCKS.EMBEDDED_ENTRY]: (node) => inlineBlock(node),
                [INLINES.HYPERLINK]: ({ data, content }) => {
                    const nodeData = data,
                        { value } = content[0],
                        url = nodeData.uri,
                        isInternal =
                            url.indexOf('viome.com') !== -1 ||
                            url.charAt(0) === '#';

                    return (
                        <>
                            {value && (
                                <a
                                    href={url}
                                    target={`${
                                        isInternal ? '_self' : '_blank'
                                    }`}
                                    rel={`${
                                        isInternal ? '' : 'noopener noreferrer'
                                    }`}
                                    key={value}
                                >
                                    {value}
                                </a>
                            )}
                        </>
                    );
                },
            },
            renderMark: {
                [MARKS.UNDERLINE]: (textValue) => animate(textValue),
            },
            renderText: (txt) =>
                txt.split('\n').map((t, i) =>
                    i > 0 ? (
                        <React.Fragment key={Math.random()}>
                            <br />
                            {t}
                        </React.Fragment>
                    ) : (
                        t
                    ),
                ),
        };

    let clickOptions = {};

    if (onClick) {
        clickOptions = {
            onClick,
            onKeyPress: onClick,
            role: 'button',
            tabIndex: 0,
        };
    }

    return (
        <>
            {text && (
                <div
                    className={classNames(
                        'viome__section__body',
                        bodyClass,
                        parsedClasses,
                        sectionStyle && sectionStyle.body,
                        sectionStyle && sectionStyle?.moduleStyles?.body,
                    )}
                    style={bodyCSS}
                    {...clickOptions}
                >
                    {prebody && <>{prebody}</>}
                    <ConditionalWrapper
                        condition={prebody}
                        wrapper={(nodeChildData) => (
                            <div className={classNames('body__content')}>
                                {nodeChildData}
                            </div>
                        )}
                    >
                        {documentToReactComponents(text, options)}
                    </ConditionalWrapper>
                    {children && <>{children}</>}
                </div>
            )}
            {settings?.messages && (
                <div
                    className={classNames(
                        'messages__container',
                        sectionStyle && sectionStyle.messages__container,
                    )}
                >
                    {settings?.messages.map((messageItem) => (
                        <div
                            className={classNames(
                                'messages__item',
                                sectionStyle && sectionStyle.messages__item,
                            )}
                            key={messageItem}
                        >
                            <div
                                className={classNames(
                                    'messages__body',
                                    sectionStyle && sectionStyle.messages__body,
                                )}
                            >
                                {messageItem}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {media && !disableMedia && (
                <Image
                    image={{
                        images: media,
                        options: settings,
                    }}
                />
            )}
            {moduleParser ? (
                moduleParser(modules)
            ) : (
                <SectionModules
                    modules={modules}
                    styles={sectionStyle}
                    moduleWrapper={moduleWrapper}
                />
            )}
        </>
    );
}
